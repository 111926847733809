import axios from 'axios'
import mock from '../mock/boughtbyid';

export const moduleName = 'boughtbyid'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`

const initialState = {
  response: null,
}

export default (state = initialState, action) => {
    const { type, response } = action

    if(type === FETCH_REQUEST){
      return {
        ...state,
        response
      }
    }

    return state;
}

export const fetchAll = (api, id) => async dispatch => {
    //dispatch({ type: FETCH_REQUEST, response: mock })
    await axios
      .get(api + '/ajax/json.php', { params: {page: 'for_owners',  object: id} })
      .then(({ data }) => data)
      .then(response => dispatch({ type: FETCH_REQUEST, response }))
}
