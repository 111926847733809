import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import Logo, {LogoScroll} from './logo'
import Phone from './phone'
import HeaderMenu from './header-menu'
import FileIcon from './file-icon'
import FavIcon from './fav-icon'
import PopupPhoto from './PopupPhoto';
import PopupOk from './PopupOk';
import Menu from './Menu';
import HeaderSearch from './HeaderSearch';
import MortgagePopup from '../popups/mortgagePopup';
import PopupMessage from './PopupMessage';
import PopupChangePlan from './PopupChangePlan';
import LkIcon from './lk-icon';
import CompareIcon from "./compare-icon";


const Header = ({ firstLoad, indexPage}) => {

  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(false);

  useEffect(() => {
    const body = document.body;
    if(show){
      body.classList.add('show-g-menu');
    }
    else{
      body.classList.remove('show-g-menu');
    }

    let y = 0;
    const mobileMenu = () => {
      const scr = window.scrollY;

      if(scr > y && scr > 70){
        setHide(true);
      }
      else{
        setHide(false);
      }

      y = scr;
    }

    window.addEventListener('scroll', mobileMenu);

    return () => {
      window.removeEventListener('scroll', mobileMenu);
    }
  },[show])

  const mobile = useSelector(state => state.mobile);

  if (mobile) return (
    <>
      <PopupChangePlan />
      <Menu show={show} close={() => setShow(false)} />
      <PopupOk />
      <PopupPhoto />
      <MortgagePopup/>
      <PopupMessage />
      <div className={hide ? 'header-mobile hide' : 'header-mobile'}>
        {/*<div className="search-icon ico-18"></div>*/}
        <HeaderSearch mobile={mobile} />
        <div className="menu-icon ico-3" onClick={() => setShow(true)}></div>
        <Logo />
      </div>
    </>
  )

  return (
    <>
      <PopupChangePlan />
      <PopupOk />
      <PopupPhoto />
      <MortgagePopup/>
      <PopupMessage />
      <Menu show={show} close={() => setShow(false)} />
      <div className={indexPage ? "header" : "header header--inner"}>
        <LogoScroll mainpage={indexPage} />
        {indexPage || <HeaderMenu />}
        <div className="header__right frame animate fadeInLeft" data-delay=".2s">
          <HeaderSearch />
          <Phone />
          <div className="flex">
            <LkIcon />
            <FileIcon />
            {/*<CompareIcon />*/}
            <FavIcon />
          </div>
          <div className="menu-icon" onClick={() => setShow(true)}>
              <svg width="30px" height="18px" viewBox="0 0 30 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g id="3" fill="#000000">
                          <path d="M29,10 L1,10 C0.448,10 0,9.552 0,8.999 C0,8.447 0.448,7.999 1,7.999 L29,7.999 C29.552,7.999 30,8.447 30,8.999 C30,9.552 29.552,10 29,10 Z M29,2 L1,2 C0.448,2 0,1.552 0,1 C0,0.448 0.448,0 1,0 L29,0 C29.552,0 30,0.448 30,1 C30,1.552 29.552,2 29,2 Z M1,15.999 L29,15.999 C29.552,15.999 30,16.447 30,16.999 C30,17.552 29.552,17.999 29,17.999 L1,17.999 C0.448,17.999 0,17.552 0,16.999 C0,16.447 0.448,15.999 1,15.999 Z" id="Shape"/>
                      </g>
                  </g>
              </svg>
          </div>
        </div>
      </div>
      <Logo mainpage={indexPage} />
    </>
  )
}



export default Header;
