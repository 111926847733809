import { createSelector } from 'reselect'
import axios from 'axios'

import mock from '../mock/favorites'

/**
 * Constants
 * */
export const moduleName = 'favorites'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`
export const SET_SELECTED_FLATS = `${moduleName}/SET_SELECTED_FLATS`
export const SET_SELECTED_OBJECTS = `${moduleName}/SET_SELECTED_OBJECTS`
export const SET_SELECTED_ALL = `${moduleName}/SET_SELECTED_ALL`

const defaultState = {
  response: {
    flats: [],
    objects: [],
    flatsCommerce: [],
    objectsCommerce: [],
    flatsCountry: [],
    objectsCountry: []
  },
  loaded: false,
  selectedFlats: [],
  selectedObjects: [],
  all: false
}

/**
 * Reducer
 * */
export default (state = defaultState, action) => {
  const { type, response, payload } = action

  switch (type) {
    case FETCH_REQUEST:
      return { ...state, response: {...state.response, ...response}, loaded: true }

    case SET_SELECTED_FLATS:
      return { ...state, selectedFlats: payload.selected }

    case SET_SELECTED_OBJECTS:
      return { ...state, selectedObjects: payload.selected }

    case SET_SELECTED_ALL:
      return { ...state, all: payload.selected }

    default:
      return state
  }
}


/**
 * Selectors
 * */

export const favoritesSelector = (state) => state[moduleName].response
export const selectedAllSelector = (state) => state[moduleName].all


export const favoritesAllListSelector = createSelector(
  favoritesSelector,
  (list) => {
    return [...list.flats, ...list.objects]
  }
)

/**
 * Action Creators
 * */
export const fetchAll = () => async dispatch => {
  // return dispatch({ type: FETCH_REQUEST, response: mock })
  await axios
    .get('/ajax/json.php', { params: {page: 'favorites-list'} })
    .then(({ data }) => data)
    .then(response => {
      if(response.flats && response.objects){
        dispatch({ type: FETCH_REQUEST, response })
      }
    })
}
export const removeItem = (flats, objects, api) => async dispatch => {
  await Promise.all(flats.map(id =>
    axios.get(api + '/ajax/json.php',{
      params: {
        page: 'favorites-del',
        type: 'flat',
        id
      }
    })
  ))
  await Promise.all(objects.map(id =>
    axios.get(api + '/ajax/json.php',{ params: {
        page: 'favorites-del',
        type: 'object',
        code: id
      }})
  ))
  dispatch(fetchAll(api));
}
export const setSelectedFlats = selected => ({ type: SET_SELECTED_FLATS, payload: {selected} });
export const setSelectedObjects = selected => ({ type: SET_SELECTED_OBJECTS, payload: {selected} });
export const setSelectedAll = selected => ({ type: SET_SELECTED_ALL, payload: {selected} });


