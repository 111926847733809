import { useSelector, useDispatch } from 'react-redux';
import { SvgPopupClose } from '../../icons';
import { setPopupOk } from '../../redux/modules/site';

const PopupOk = () => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.popupOk);

  if(!data) return null;

  return(
    <div className="popups fade-in">
      <div className="popups__in">
        <div className="popups__ok">
          <div className="popups__close" onClick={() => dispatch(setPopupOk(null))}>
            <SvgPopupClose />
          </div>

          <div className="popups__ok-in">
            <div className="popups__ok-title">Спасибо!</div>
            <div className="popups__ok-text">Ваша заявка успешно отправлена.<br />Наши менеджеры свяжуться с вами в ближайшее время</div>
            <div className="btn btn--border no-border-link" onClick={() => dispatch(setPopupOk(null))}><span>Закрыть</span></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupOk;