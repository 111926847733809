import { useState } from 'react'
import Link from './link'
import PropTypes from 'prop-types'


const CheckboxField = ({value, name, setValue, trigger}) => {
  const toggle = (ev) => setValue(name, !value)

  return (
    <div 
      className="checkbox-field"
      valid={value ? 'true' : 'false'}
      error={!value && trigger ? 'true' : 'false'}
      onClick={toggle}
    >
      <span className="checkbox-field__check"></span>
      <span>Согласие с <a href="/static/personal_data_processing_policy.pdf" target="_blank" className="border-link"><span>политикой обработки персональных данных</span></a></span>
    </div>
  )

}

CheckboxField.propTypes = {
  value: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  trigger: PropTypes.bool.isRequired,
}


export default CheckboxField
