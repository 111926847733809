import { createSelector } from 'reselect'
import axios from 'axios'
import mock from '../mock/objects'

/**
 * Constants
 * */
export const moduleName = 'realty'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`
export const CHANGE_FILTER = `${moduleName}/CHANGE_FILTER`


/**
 * Reducer
 * */
const defaultState = {
  filter: {
    price: [0,0],
    text: '',
  },
  objects: {
    loaded: false,
    entities: [],
  },
}

export default (state = defaultState, action) => {
  const { type, response, payload } = action

  switch (type) {
    case FETCH_REQUEST:
      return { ...state, objects: {entities: response, loaded: true} }

    // filter
    case CHANGE_FILTER:
      return { ...state, filter: {...state.filter, [payload.name]: payload.value} }

    default:
      return state
  }
}

/**
 * Selectors
 * */

export const objectsSelector = (state) => state[moduleName].objects.entities

export const priceMinMaxSelector = createSelector(
  objectsSelector,
  (list) => {
    if (!list.length) return {min: 0, max: 0}
    return {
      min: list.reduce((min, el) => Math.min(min, el['price_min']), list[0]['price_min']),
      max: list.reduce((max, el) => Math.max(max, el['price_max']), list[0]['price_max']),
    }
  }
)

export const textSelector = (state) => state[moduleName].filter.text
export const priceSelector = (state) => state[moduleName].filter.price

export const indexFiltratedSelector = createSelector(
  objectsSelector,
  priceSelector,
  textSelector,
  (list, price, txt) => list.filter(el => {
    const name = el.name.toLowerCase()
    const address = el.address.toLowerCase()
    const text = txt.toLowerCase()

    return (
      (
        (el.price_min <= price[0] && el.price_max >= price[0]) || 
        (el.price_min <= price[1] && el.price_max >= price[1])
      ) &&
      (!text || name.indexOf(text) > -1 || address.indexOf(text) > -1)
    )
  })
)


/**
 * Action Creators
 * */
export const fetchObjects = api => async dispatch => {
  dispatch({ type: FETCH_REQUEST, response: mock })
  /*await axios
    .get(api + 'posts', { params: {} })
    .then(({ data }) => data)
    .then(response => dispatch({ type: FETCH_REQUEST, response: mock }))*/
}

// filter
export const changeFilter = (value, name) => ({type: CHANGE_FILTER, payload: {value, name}})
