import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useTabs } from '../../hooks/use-tabs'
import Link from './link'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { SvgSelectArrow } from '../../icons';
import { fetchFilter } from '../../redux/modules/apartment-search'
import {openModal} from '../../redux/modules/form'
import {connect} from 'react-redux'
// import {menuTopSelector} from "../../redux/modules/site";

const HeaderMenu = ({indexPage,openModal}) => {
  const {active, setTab} = useTabs(null)
  const router = useRouter();
  const dispatch = useDispatch();
  const filter = useSelector(state => state.apartmentSearch.filter);
  // const menu = useSelector(menuTopSelector)

  const selectData = {
    catalog_new: 'Недвижимость жилая',
    catalog_cre: 'Недвижимость коммерческая',
    catalog_country: 'Недвижимость загородная',
  }

  const [select, setSelect] = useState('catalog_new');

  useEffect(() => {
    if(router.query.slug){
      setSelect(router.query.slug[0])
    }
  },[router.query]);

  useEffect(() => {
    if(!filter){
      dispatch(fetchFilter('',{}));
    }
  },[filter]);

  return (
    <div className="header-menu__links animate" data-delay='.2s' data-open={!!active}>
      <div className="header-menu-select">
        <Select
          MenuProps={{
            classes: {paper: 'header-menu-select-menu'}
          }}
          renderValue={value => <div>{selectData[value]}</div>}
          value={select}
          IconComponent={() => <SvgSelectArrow />}
        >
          {filter?.map(item => {
            return <MenuItem key={item.type} value={item.type}><Link to={'/' + item.type}><i>{selectData[item.type]}</i></Link></MenuItem>
          })}
        </Select>
      </div>
      <Link to="/services">Услуги</Link>
      <Link to="/actions">Спецпредложения</Link>
      <Link to="/consulting">Консалтинг</Link>
      <Link to="/for-those-who-bought">Для купивших</Link>
      {/*{Array.isArray(menu) && menu.map(el => <Link to={el.url} key={el.url}>{el.name}</Link>)}*/}
    </div>
  )
}



export default connect(
  state => ({mobile: state.mobile}), {openModal}
)(HeaderMenu)

