import axios from 'axios'

import {newData} from '../mock/about'

/**
 * Constants
 * */
export const moduleName = 'about'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`
export const CHANGE_TAB = `${moduleName}/CHANGE_TAB`

const defaultState = {
  response: [],
  tab: 'smi',
  loaded: false
}

/**
 * Reducer
 * */
export default (state = defaultState, action) => {
  const { type, response, payload } = action

  switch (type) {
    case FETCH_REQUEST:
      return { ...state, response, loaded: true }

    case CHANGE_TAB:
        return {...state, tab: response}

    default:
      return state
  }
}

/**
 * Action Creators
 * */
export const fetchAll = (api) => async dispatch => {
  // dispatch({ type: FETCH_REQUEST, response: newData })
  await axios
    .get(api + '/ajax/json.php', { params: {page: 'about'} })
    .then(({ data }) => data)
    .then(response => {
      dispatch({ type: FETCH_REQUEST, response })
    })
}

export const changeTab = response => dispatch => dispatch({ type: CHANGE_TAB, response });
