import axios from 'axios'
import {newData} from '../mock/partners'

/**
 * Constants
 * */
export const moduleName = 'banks'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`
export const SET_NEWS_FEED_TYPE = `${moduleName}/SET_NEWS_FEED_TYPE`

const defaultState = {
  response: {},
  loaded: false
}

/**
 * Reducer
 * */
export default (state = defaultState, action) => {
  const { type, response, payload } = action

  switch (type) {
    case FETCH_REQUEST:
      return { ...state, response, loaded: true }

    default:
      return state
  }
}

/**
 * Action Creators
 * */
export const fetchAll = (api, id) => async dispatch => {
  //dispatch({ type: FETCH_REQUEST, response: newData })
  await axios
    .get(api + '/ajax/json.php', { params: {page: 'bank', id} })
    .then(({ data }) => data)
    .then(response => dispatch({ type: FETCH_REQUEST, response }))
}
