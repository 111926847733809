import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAll } from '../../redux/modules/favorites';

const LoadFavorite = () => {
  const dispatch = useDispatch();
  const favorites = useSelector(state => state.favorites);

  useEffect(() => {
    dispatch(fetchAll())
  },[]);

  return null;
}

export default LoadFavorite;