import axios from 'axios'

import mock from '../mock/index'

/**
 * Constants
 * */
export const moduleName = 'recommended'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`
export const FETCH_DETAIL = `${moduleName}/FETCH_DETAIL`
export const ERROR = `${moduleName}/ERROR`

const defaultState = {
  response: {},
  loaded: false,
  detail: {info: {}},
  error: false
}

/**
 * Reducer
 * */
export default (state = defaultState, action) => {
  const { type, response } = action

  switch (type) {
    case FETCH_REQUEST:
      return { ...state, response, loaded: true, error: false }

    case FETCH_DETAIL:
      return { ...state, detail:response, error: false }

    case ERROR:
      return { ...state, error: true }

    default:
      return state
  }
}

/**
 * Action Creators
 * */
export const fetchAll = (api) => async dispatch => {
  await axios
      .get(api + '/ajax/json.php', { params: {page: 'recommends_page', count: 'all'} })
      .then(({ data }) => data)
      .then(response => dispatch({ type: FETCH_REQUEST, response }))
}

export const fetchDetail = (api, set) => async dispatch => {
  await axios
      .get(api + '/ajax/json.php', { params: {page: 'recommends_page', set} })
      .then(({ data }) => data)
      .then(response => {
        if (response?.error) return dispatch({ type: ERROR })
        return dispatch({ type: FETCH_DETAIL, response })
      })
      .catch(() => {})
}
