
import Link from 'next/link';
import { useSelector } from 'react-redux';
import { SvgMenuClose, SvgMenuPhone, SvgLikeEmpty, SvgLikeActive, SvgLkTop } from '../../icons';
import React from "react";
import {menuMainSelector} from "../../redux/modules/site";

const Menu = ({show, close}) => {
    const auth = useSelector(state => state.auth);
    const fav = useSelector(state => state.favorites);
    const flats = fav.response.flats;
    const objects = fav.response.objects;

    const data = useSelector(menuMainSelector)

    const call = () => {
        if(window && window.ComagicWidget) ComagicWidget.openSitePhonePanel();
    }

    return(
        <div className={show ? 'g-menu active' : 'g-menu'} onMouseLeave={close}>
            <div className="g-menu__close" onClick={close}><SvgMenuClose /></div>
            <div className="g-menu__items">
                {Array.isArray(data) && data.map((item, index) =>
                    <div className={item.underline ? 'line' : ''} onClick={close} key={item.id}><Link href={item.url ? item.url : ''}><a><span>{item.name}</span></a></Link>
                        {item.children && <div className="sub">
                            {item.children && item.children.map((item, index) =>
                                <div className={item.underline ? 'line' : ''} onClick={close} key={item.id}><Link href={item.url ? item.url : ''}><a><span>{item.name}</span></a></Link></div>
                            )}
                        </div>}
                    </div>
                )}
            </div>

            <div className="g-menu__lnk" onClick={call}>
                <div><span className="g-menu__lnk-icon"><SvgMenuPhone /></span></div>
                <div>Заказать звонок</div>
            </div>

            <Link href="/favorites">
                <a className="g-menu__lnk" onClick={close}>
                    <div><span className="g-menu__lnk-icon">{flats.length || objects.length ? <SvgLikeActive /> : <SvgLikeEmpty />}</span></div>
                    <div>Избранное</div>
                </a>
            </Link>
            {/*<Link href="/compare">*/}
            {/*    <a className="g-menu__lnk" onClick={close}>*/}
            {/*        <div><span className="g-menu__lnk-icon"><svg width={25} height={19} viewBox="0 0 25 19" xmlns="http://www.w3.org/2000/svg" >*/}
            {/*<path d="M23.967,15.0605 L14.548,15.0605 L16.689,17.2275 C17.094,17.6375 17.094,18.3025 16.689,18.7125 C16.283,19.1235 15.627,19.1235 15.221,18.7125 L11.309,14.7525 C10.904,14.3425 10.904,13.6775 11.309,13.2675 L15.221,9.3075 C15.627,8.8975 16.283,8.8975 16.689,9.3075 C17.094,9.7175 17.094,10.3825 16.689,10.7925 L14.548,12.9605 L23.967,12.9605 C24.539,12.9605 25.004,13.4305 25.004,14.0105 C25.004,14.5905 24.539,15.0605 23.967,15.0605 Z M9.783,9.7135 C9.377,10.1235 8.721,10.1235 8.315,9.7135 C7.91,9.3025 7.91,8.6385 8.315,8.2275 L10.456,6.0605 L1.037,6.0605 C0.465,6.0605 0,5.5905 0,5.0105 C0,4.4305 0.465,3.9595 1.037,3.9595 L10.456,3.9595 L8.315,1.7925 C7.91,1.3825 7.91,0.7175 8.315,0.3075 C8.721,-0.1025 9.377,-0.1025 9.783,0.3075 L13.695,4.2675 C14.1,4.6775 14.1,5.3425 13.695,5.7525 L9.783,9.7135 Z"  fill="#C3C3C3" /></svg></span></div>*/}
            {/*        <div>Сравнение</div>*/}
            {/*    </a>*/}
            {/*</Link>*/}


            {!auth && (
                <Link href="/online-registration">
                    <a className="g-menu__lnk g-menu__lnk-lk" onClick={close}>
                        <div><span className="g-menu__lnk-icon"><SvgLkTop /></span></div>
                        <div>Личный кабинет</div>
                    </a>
                </Link>
            )}

            {auth && (
                <Link href="/lk">
                    <a className="g-menu__lnk g-menu__lnk-lk active" onClick={close}>
                        <div><span className="g-menu__lnk-icon"><SvgLkTop /></span></div>
                        <div>Личный кабинет</div>
                    </a>
                </Link>
            )}
        </div>
    )
}

export default Menu;
