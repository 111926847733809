import { combineReducers } from 'redux'
import {
    mobileReducer,
    loadingReducer,
    firstLoadReducer,
    popupPhoto,
    popupOk,
    saveSearch,
    popupMessage,
    popupChangePlan,
    authReducer,
    homeSelectFirstLoad,
    menuReducer,
  redirectsReducer
} from './modules/site';
import mainReducer, { moduleName as mainModule } from './modules/index';
import realtyReducer, { moduleName as realtyModule } from './modules/realty';
import newsFeedReducer, { moduleName as newsFeedModule } from './modules/news-feed';
import journalReducer, { moduleName as journalModule } from './modules/journal';
import articlesdetailReducer, { moduleName as articlesdetailModule } from './modules/articlesdetail';
import servicesReducer, { moduleName as servicesModule } from './modules/services';
import apartmentBookingReducer, { moduleName as apartmentBookingModule } from './modules/apartment-booking';
import aboutReducer, { moduleName as aboutModule } from './modules/about';
import newsReducer, { moduleName as newsModule } from './modules/news';
import newsdetailReducer, { moduleName as newsdetailModule } from './modules/newsdetail';
import apartmentSearch, { moduleName as apartmentSearchModule } from './modules/apartment-search';
import boughtReducer, { moduleName as boughtModule } from './modules/bought';
import favoritesReducer, { moduleName as favoritesModule } from './modules/favorites';
import compareReducer, { moduleName as compareModule } from './modules/compare';
import promoReducer, { moduleName as promoModule } from './modules/promo';
import promodetailReducer, { moduleName as promodetailModule } from './modules/promodetail';
import howbuyReducer, { moduleName as howbuyModule } from './modules/how-buy';
import formReducer, { moduleName as formModule } from './modules/form';
import onlineRegistrationReducer, { moduleName as onlineRegistrationModule } from './modules/online-registration';
import mortgageReducer, { moduleName as mortgageModule } from './modules/mortgage';
import consultingReducer, { moduleName as consultingModule } from './modules/consulting';
import buildingReducer, { moduleName as buildingModule } from './modules/building';
import partnersReducer, { moduleName as partnersModule } from './modules/partners';
import recommendsReducer, { moduleName as recommendsModule } from './modules/recommends';
import contactsReducer, { moduleName as contactsModule } from './modules/contacts';
import calcReducer, { moduleName as calcModule } from './modules/calc';
import forDeveloperReducer, { moduleName as forDeveloperModule } from './modules/for-developer';
import banksReducer, { moduleName as banksModule } from './modules/banks';
import recommendReducer, { moduleName as recommendModule } from './modules/recommend';
import boughtbyidReducer, { moduleName as boughtbyidModule } from './modules/boughtbyid';
import recentReducer, { moduleName as recentModule } from './modules/recent';
import agentsReducer, { moduleName as agentsModule } from './modules/agents';
import pressSmiReducer, { moduleName as pressSmiModule } from './modules/press-smi';
import pressPressReducer, { moduleName as pressPressModule } from './modules/press-press';
import pressdetailReducer, { moduleName as pressdetailModule } from './modules/pressdetail';
import recommendedReducer, { moduleName as recommendedModule } from './modules/recommended';
import articlesReducer, { moduleName as articlesModule } from './modules/articles';
//import nofoundReducer, { moduleName as nofoundModule } from './modules/404';

//import franchiseReducer, { moduleName as franchiseModule } from './modules/franchise';
import servicesdetailReducer, { moduleName as servicesdetailModule } from './modules/servicesdetail';


//import nofoundReducer, { moduleName as nofoundModule } from './modules/404';


const reducers = {
    homeSelectFirstLoad,
    auth: authReducer,
    popupChangePlan,
    saveSearch,
    popupMessage,
    popupOk: popupOk,
    popupPhoto: popupPhoto,
    firstLoad: firstLoadReducer,
    mobile: mobileReducer,
    loading: loadingReducer,
  menu: menuReducer,
  redirects: redirectsReducer,
    [mainModule]: mainReducer,
    [realtyModule]: realtyReducer,
    [newsFeedModule]: newsFeedReducer,
    [journalModule]: journalReducer,
    [articlesdetailModule]: articlesdetailReducer,
    [servicesModule]: servicesReducer,
    [apartmentBookingModule]: apartmentBookingReducer,
    [aboutModule]: aboutReducer,
    [newsModule]: newsReducer,
    [newsdetailModule]: newsdetailReducer,
    [apartmentSearchModule]: apartmentSearch,
    [boughtModule]: boughtReducer,
    [favoritesModule]: favoritesReducer,
    [compareModule]: compareReducer,
    [promoModule]: promoReducer,
    [promodetailModule]: promodetailReducer,
    [howbuyModule]: howbuyReducer,
    [formModule]: formReducer,
    [onlineRegistrationModule]: onlineRegistrationReducer,
    [mortgageModule]: mortgageReducer,
    [consultingModule]: consultingReducer,
    [buildingModule]: buildingReducer,
    [partnersModule]: partnersReducer,
    [recommendsModule]: recommendsReducer,
    [contactsModule]: contactsReducer,
    [calcModule]: calcReducer,
    [forDeveloperModule]: forDeveloperReducer,
    [banksModule]: banksReducer,
    [recommendModule]: recommendReducer,
    [boughtbyidModule]: boughtbyidReducer,
    [recentModule]: recentReducer,
    [agentsModule]: agentsReducer,
    [pressSmiModule]: pressSmiReducer,
    [pressPressModule]: pressPressReducer,
    [pressdetailModule]: pressdetailReducer,
    [recommendedModule]: recommendedReducer,
    //[nofoundModule]: nofoundReducer,
    //[franchiseModule]: franchiseReducer,
    [servicesdetailModule]: servicesdetailReducer,
    [articlesModule]: articlesReducer,
}

export default combineReducers(reducers)
