import { createSelector } from 'reselect'
import { distinct } from '../../utils'
import axios from 'axios'
import mock from '../mock/news-feed'

/**
 * Constants
 * */
export const moduleName = 'newsFeed'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`
export const FETCH_REQUEST_LAZY = `${moduleName}/FETCH_REQUEST_LAZY`
export const CHANGE_FILTER = `${moduleName}/CHANGE_FILTER`
export const SET_LIMIT = `${moduleName}/SET_LIMIT`
export const SET_ACTIVE_LIST = `${moduleName}/SET_ACTIVE_LIST`


/**
 * Reducer
 * */
const defaultState = {
  articles: {
    items: []
  },
  loaded: false,
  filters: {
    type: [],
    page: 1,
    limit: 3
  },
  activeList:[]
}

export default (state = defaultState, action) => {
  const { type, response, payload } = action

  switch (type) {
    case FETCH_REQUEST:
      return { ...state, ...response, loaded: true }

    case FETCH_REQUEST_LAZY:

      const tmp = [...state.articles.items]
      const result = tmp.concat(response.articles.items);

      return {
        ...state,
        ...response,
        articles: {
          ...response.articles,
          items: result
        },
      }

    // filter
    case CHANGE_FILTER:
      return { ...state, filters: {...state.filters, type: payload.value, limit: defaultState.filters.limit} }

    case SET_LIMIT:
      return { ...state, filters: {...state.filters, limit: payload.limit} }

    case SET_ACTIVE_LIST:
      return { ...state, activeList: payload.list }




    default:
      return state
  }
}

/**
 * Selectors
 * */

export const allnewsSelector = (state) => state[moduleName].articles.items
export const typeSelector = (state) => state[moduleName].filters.type
export const pageSelector = (state) => state[moduleName].filters.page
export const limitSelector = (state) => state[moduleName].filters.limit

export const newsSelector = (state) => state[moduleName].filters.actions
export const activeListSelector = (state) => state[moduleName].activeList

//export const newsSelector = createSelector(
 // allnewsSelector,
//  typeSelector,
//  (list, type) => {
    //return list.filter((el, i) => el.type !== 'Спецпредложения' )
//  }
//)

//export const typeOptionsSelector = createSelector(
//  newsSelector,
//  (list) => {
    //return distinct(list, 'type')
//  }
//)
export const filtratedByTypeSelector = createSelector(
  newsSelector,
  typeSelector,
  (list, type) => {
    //return list.filter((el, i) => !type.length || type.includes(el.type) )
  }
)
export const filtratedSelector = createSelector(
  filtratedByTypeSelector,
  pageSelector,
  limitSelector,
  (list, page, limit) => {
    const offset = (page - 1) * limit

    //return list.filter((el, i) => !page || (i >= offset && i < offset + limit))
  }
)

//export const newsMainSelector = createSelector(
//  allnewsSelector,
//  (list) => {
//    //return list.filter((el) => el.type === 'Главное').slice(0, 30)
//  }
//)

//export const newsActionsSelector = createSelector(
//  allnewsSelector,
//  (list) => {
//    //return list.filter((el) => el.type === 'Спецпредложения').slice(0, 30)
//  }
//)


/**
 * Action Creators
 * */
export const fetchNews = (api, params) => async dispatch => {
  await axios
      .get(api + '/ajax/json.php?page=events', { params })
      .then(({ data }) => data)
      .then(response => dispatch({ type: FETCH_REQUEST, response }))
}

export const fetchNewsLazy = (api, params, callback) => async dispatch => {
  await axios
      .get(api + '/ajax/json.php?page=events', { params })
      .then(({ data }) => data)
      .then(response => {
        if(callback){
          callback();
        }
        dispatch({ type: FETCH_REQUEST_LAZY, response })
      })
}

export const setActiveList = (list) => ({type: SET_ACTIVE_LIST, payload: {list}})
