import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Link from 'next/link';
import axios from 'axios';
import { SvgLkTop } from '../../icons';
import { setAuth } from '../../redux/modules/site';

const LkIcon = () => {
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const [open, setOpen] = useState(false)

  useEffect(() => {
    axios.get('/ajax/json.php?page=user-auth')
    .then(function ({data}) {
      if(data){
        if(data.auth){

          axios
          .get('/ajax/json.php?page=user-info')
          .then(({ data }) => data)
          .then(response => {
            if(response.phone){
              dispatch(setAuth(response))
            }
          })
          .catch(function (error) {
            //dispatch(setAuth(true))
          })
          .then(function () {
            //dispatch(setAuth(true))
          });

        }
      }
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {

    });
  },[]);

  if(!auth){
    return (
      <Link href="/online-registration">
        <a className="lk-header" onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
          {open && <div className="info-bubble">Личный кабинет</div>}
          <SvgLkTop />
        </a>
      </Link>
    )
  }

  return (
    <Link href="/lk">
      <a className="lk-header active" onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
        {open && <div className="info-bubble">Личный кабинет</div>}
        <SvgLkTop />
      </a>
    </Link>
  )
}

export default LkIcon