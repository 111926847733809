import axios from 'axios'

import {newData} from '../mock/pressdetail'


/**
 * Constants
 * */
export const moduleName = 'pressdetail'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`

const defaultState = {
    response: [],
    loaded: false
}

/**
 * Reducer
 * */
export default (state = defaultState, action) => {
    const { type, response, payload } = action

    switch (type) {
        case FETCH_REQUEST:
            return { ...state, response, loaded: true }

        default:
            return state
    }
}

/**
 * Action Creators
 * */
export const fetchAll = (api, id, page) => async dispatch => {
    // dispatch({ type: FETCH_REQUEST, response: newData })
   await axios
        .get(api + '/ajax/json.php', { params: { page, id } })
        .then(({ data }) => data)
        .then(response => dispatch({ type: FETCH_REQUEST, response }))
}
