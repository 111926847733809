import { useState, useEffect } from 'react';
import Router, { useRouter } from 'next/router'
import { useSelector } from 'react-redux';

const HeaderSearch = ({mobile}) => {
  const router = useRouter()
  const [text, setText] = useState('');
  const [active, setActive] = useState(false);

  /*useEffect(() => {
    setText(router.query.keyword)
  },[router.query.keyword]);*/

  const enter = e => {
    if(e.keyCode === 13) {
      submit();
    }
  }

  const submit = () => {
    setActive(false);
    Router.push({
      pathname: '/search',
      query: { keyword: text },
    })
  }

  if(mobile){
    return(
      <div className={active ? 'search-icon active' : 'search-icon'}>
        <div className="search-icon-in ico-18" onClick={() => setActive(!active)}></div>
        <div className="header__search">
          <input type="text" value={text} onChange={e => setText(e.target.value)} placeholder="Введите слово для поиска" onKeyDown={e => enter(e)} onBlur={submit} />
        </div>
      </div>
    )
  }

  return(
    <div className="search-icon">
      <div className="search-icon-in ico-18 scale-on-hover" onClick={submit}></div>
      <div className="header__search">
        <input type="text" value={text} onChange={e => setText(e.target.value)} placeholder="Введите слово для поиска" onKeyDown={e => enter(e)} />
      </div>
    </div>
  )
}

export default HeaderSearch;