import {usePageLoading} from '../../hooks/use-page-loading'
import {useSelector} from 'react-redux'
import { useRouter } from 'next/router'
import Header from './header'
import Footer from './footer'
import Modal from '../form/modal'
import Feedback from '../form/feedback'
import Cookie from "./cookie";
//import {redirectsSelector} from "../../redux/modules/site";
//import {useEffect} from "react";

const Container = ({ children, res, ...props }) => {
  const firstLoad = useSelector(state => state.firstLoad)
  //const menuTop = useSelector(menuTopSelector)
  const router = useRouter()
  const [loading, logoAnimated, logoLoaded] = usePageLoading(router.pathname === '/')
  //const redirects = useSelector(redirectsSelector);

/*  useEffect(() => {
    if(redirects?.redirects){
      let filtered_redirects = redirects?.redirects.filter(item => item.from === router.asPath)[0];
      if (filtered_redirects){
        router.push(filtered_redirects.to);
      }
    }
  },[])*/

  if (firstLoad) return (
    <div className="page">
      <Header indexPage={router.pathname === '/'} />
      {children}
      <Footer />
      <span className="scroll-helper" />
    </div>
  )

  return (
    <div className={`page${loading ? ' page-loading' : ''}`}>
      {logoLoaded && <Header indexPage={router.pathname === '/'} />}
      {logoAnimated || children}
      <div className={logoAnimated ? 'hidden-footer' : ''}><Footer /></div>
      <span className="scroll-helper" />
      <Modal type="feedback"><Feedback /></Modal>
      <Cookie />
    </div>
  )
}

export default Container
