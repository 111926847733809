import { useSelector, useDispatch } from 'react-redux';
import { setPopupMessage } from '../../redux/modules/site';
import { SvgPopupClose } from '../../icons';

const PopupMessage = () => {
  const dispatch = useDispatch();
  const popupMessage = useSelector(state => state.popupMessage);

  if(!popupMessage.title && !popupMessage.text) return null;

  return(
    <div className="popups fade-in">
      <div className="popups__in">
        <div className="popups__ok">
          <div className="popups__close" onClick={() => dispatch(setPopupMessage({title: null, text: null}))}>
            <SvgPopupClose />
          </div>

          <div className="popups__ok-in">
            {popupMessage.title && <div className="popups__ok-title" dangerouslySetInnerHTML={{ __html: popupMessage.title }} />}
            {popupMessage.text && <div className="popups__ok-text" dangerouslySetInnerHTML={{ __html: popupMessage.text }} />}
            <div className="btn btn--border no-border-link" onClick={() => dispatch(setPopupMessage({title: null, text: null}))}><span>Закрыть</span></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupMessage;