import axios from 'axios'
//import { newData } from '../mock/contacts'

export const moduleName = 'contacts'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`
export const SET_NEWS_FEED_TYPE = `${moduleName}/SET_NEWS_FEED_TYPE`

const defaultState = {
  response: {},
  loaded: false
}

export default (state = defaultState, action) => {
  const { type, response, payload } = action

  switch (type) {
    case FETCH_REQUEST:
      return { ...state, response, loaded: true }

    default:
      return state
  }
}

export const fetchAll = (api) => async dispatch => {
  await axios
    .get(api + '/ajax/json.php', { params: {page: 'contacts'} })
    .then(({ data }) => data)
    .then(response => dispatch({ type: FETCH_REQUEST, response }))
}
