import axios from 'axios'

import { mock } from '../mock/online-registration'

/**
 * Constants
 * */
export const moduleName = 'onlineRegistration'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`

const defaultState = {
  loaded: false
}

/**
 * Reducer
 * */
export default (state = defaultState, action) => {
  const { type, response, payload } = action

  if(type === FETCH_REQUEST){
    return {
      ...state,
      response,
      loaded: true
    }
  }

  return state
}

/**
 * Action Creators
 * */
export const fetchAll = api => async dispatch => {
  await axios
    .get(api + '/ajax/json.php?page=online_registration')
    .then(({ data }) => data)
    .then(response => dispatch({ type: FETCH_REQUEST, response }))
}
