import { useState } from 'react'
import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'

const limits = {
  email: {
    regex: /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-]+\.[A-Za-z]+$/
  },
  name: {
    regex: /^[а-яА-Я-]+$/
  },
  password: {
    regex: /^[a-zA-Z0-9-_.$%#]+$/,
    min: 6
  },
  text: {
    min: 1
  },
  textarea: {
    min: 10
  },
  phone: {
    regex: /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/
  },
  cod: {
    regex: /^[0-9]+$/
  },
  sms: {
    regex: /^[0-9]+$/,
    min: 6
  },
}

const InputField = ({value, name, type, placeholder, valid, validate, setFieldValid, setValue, trigger, className, smsSend}) => {
  const isValidField = () => {
    const limit = limits[validate]
    if (!limit) return true

    if (limit.regex && !limit.regex.test(value)) {
      return false
    }

    if ((limit.min && !value) || (limit.min && value.length < limit.min)) {
      return false
    }

    if (typeof smsSend !== 'undefined') return smsSend

    return true
  }

  const handleBlur = (ev) => {
    const valid = isValidField()
    setFieldValid(name, valid, valid)
  }

  const handleFocus = (ev) => setFieldValid(name, true, isValidField())

  return (
    <div
      className="input-field text-field"
      data-valid={valid || (!value && !trigger) ? 'true' : 'false'}
      data-error={!valid && trigger ?'true' : 'false'}
    >
      <label>
        {type === 'textarea' ? (
          <textarea
            name={name}
            type={type}
            onBlur={handleBlur}
            onFocus={handleFocus}
            value={value}
            onChange={setValue}
          >
            {value}
          </textarea>
        ) : validate === 'phone' ? (
          <InputMask
            mask={validate === 'phone' ? '+7 (999) 999-99-99' : '99.99.9999'}
            placeholder={placeholder}
            name={name}
            type={type}
            onBlur={handleBlur}
            onFocus={handleFocus}
            value={value}
            onChange={setValue}
          />
        ) : validate === 'cod' ? (
            <InputMask
                mask={'9999'}
                placeholder={placeholder}
                name={name}
                type={type}
                onBlur={handleBlur}
                onFocus={handleFocus}
                value={value}
                onChange={setValue}
            />
        ) : (
          <input
            name={name}
            type={type}
            value={value}
            placeholder={placeholder}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={setValue}
            autoComplete="off"
          />
        )}
      </label>
    </div>
  )

}

InputField.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  valid: PropTypes.bool.isRequired,
  validate: PropTypes.string,
  setFieldValid: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  trigger: PropTypes.bool.isRequired,
}


export default InputField
