import axios from 'axios'
import { createSelector } from 'reselect'

import {newData} from '../mock/calc'


/**
 * Constants
 * */
export const moduleName = 'calc'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`
export const SET_PERCENT_DEFAULT = `${moduleName}/SET_PERCENT_DEFAULT`
export const SET_CALC_ALL = `${moduleName}/SET_CALC_ALL`
export const SHOW_CALC_POPUP = `${moduleName}/SHOW_CALC_POPUP`

const defaultState = {
  response: [],
  defaultPercent: null,
  calcAll: null,
  loaded: false,
  popup: false
}

/**
 * Reducer
 * */
export default (state = defaultState, action) => {
  const { type, response, payload } = action

  switch (type) {
    case FETCH_REQUEST:
      return { ...state, response, loaded: true }

    case SET_PERCENT_DEFAULT:
      return { ...state, defaultPercent: response }

    case SET_CALC_ALL:
      return { ...state, calcAll: response }

    case SHOW_CALC_POPUP:
      return { ...state, popup: response }

    default:
      return state
  }
}

/**
 * Selectors
 * */

export const allProgrammsSelector = (state) => state[moduleName].response.programs

export const banksSelector = createSelector(
  allProgrammsSelector,
  (list) => {
    let banks = [];

    list.forEach((item, index) => {
      if(!banks[item.BANK_CODE]) {
          banks[item.BANK_CODE] = {
              'name': item.BANK_NAME,
              'programms': [item],
              'img': item.BANK_IMG,
              index
          }
      } else {
          banks[item.BANK_CODE].programms.push(item)
      }
    });

    banks.sort((a, b) => parseFloat(a['programms']['0']['ANNUAL_PERCENT']) - parseFloat(b['programms']['0']['ANNUAL_PERCENT']))

    return banks;
   }
)

/**
 * Action Creators
 * */
export const fetchCalc = (api) => async dispatch => {
  //dispatch({ type: FETCH_REQUEST, response: newData })
  await axios
    .get(api + '/ajax/json.php', { params: {page: 'mortgage-calc'} })
    .then(({ data }) => data)
    .then(response => {
      dispatch({ type: FETCH_REQUEST, response })
    })

}

export const fetchCalcDigital = (api) => async dispatch => {
  //dispatch({ type: FETCH_REQUEST, response: newData })
  await axios
    .get(api + '/ajax/json.php', { params: {page: 'mortgage-calc-digital'} })
    .then(({ data }) => data)
    .then(response => {
      dispatch({ type: FETCH_REQUEST, response })
    })

}


export const setPercentDefault = response => dispatch => dispatch({ type: SET_PERCENT_DEFAULT, response });

export const setCalcAll = response => dispatch => dispatch({ type: SET_CALC_ALL, response });

export const setCalcPopup = response => dispatch => dispatch({ type: SHOW_CALC_POPUP, response });
