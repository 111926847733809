import axios from 'axios'
import mock from '../mock/franchise'

/**
 * Constants
 * */
export const moduleName = 'franchise'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`

const defaultState = {
    response: {},
    loaded: false
}

/**
 * Reducer
 * */
export default (state = defaultState, action) => {
    const { type, response, payload } = action

    switch (type) {
        case FETCH_REQUEST:
            return { ...state, response: response,  loaded: false }

        //case SET_ACTIVE_TAB_SERVICES:
        //  return { ...state, tab: response }

        default:
            return state
    }
}

/**
 * Action Creators
 * */
export const fetchAll = (api, id) => async dispatch => {
    //return dispatch({ type: FETCH_REQUEST, response: mock })
    await axios
        .get(api + '/ajax/json.php', { params: {page: "franchise", code: id} })
        .then(({ data }) => data)
        .then(response => dispatch({ type: FETCH_REQUEST, response }))
}
