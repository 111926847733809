import { useSelector, useDispatch } from 'react-redux';
import { setPopupChangePlan, selectPopupChangePlan } from '../../redux/modules/site';

const PopupChangePlan = () => {
  const dispatch = useDispatch();
  const popupChangePlan = useSelector(state => state.popupChangePlan);

  if(!popupChangePlan.data) return null;

  const change = value => {
    const array = [...popupChangePlan.select];
    const index = array.indexOf(value);
    if(index === -1){
      array.push(value);
    }
    else{
      array.splice(index, 1);
    }

    dispatch(selectPopupChangePlan(array));
  }

  const checkAll = () => {
    if(popupChangePlan.data.data.length == popupChangePlan.select.length){
      dispatch(selectPopupChangePlan([]));
    }
    else{
      const array = [];
      popupChangePlan.data.data.forEach(item => array.push(item.value));
      dispatch(selectPopupChangePlan(array));
    }
  }

  return(
    <div className="popup popup-change-plan">
      <div className="popup__in">
        <div className="popup__close" onClick={() => dispatch(setPopupChangePlan(null))}></div>
        <div className="popup-change-plan__title-wrap">
          <div className="popup-change-plan__title">{popupChangePlan.data.label}</div>
          <div className={popupChangePlan.data.data.length == popupChangePlan.select.length ? 'popup-change-plan-chack-all active' : 'popup-change-plan-chack-all'} onClick={checkAll}>
            <div className="checkbox-field__check"></div>
            Выбрать все
          </div>
        </div>

        <div className="popup-change-plan-boxes">
          {popupChangePlan.data.data.map(item => (
            <div className={popupChangePlan.select.indexOf(item.value) !== -1 ? 'popup-change-plan-box active' : 'popup-change-plan-box'} key={`item${item.value}`} onClick={() => change(item.value)}>
              <div className="popup-change-plan-box-in">
                <img src={item.name} />
                <div className="checkbox-field__check"></div>
              </div>
              {item.title} {item.count ? `(${item.count})` : ''}
            </div>
          ))}
        </div>

        <div className="btn btn--border no-border-link" onClick={() => dispatch(setPopupChangePlan(null))}><span>Выбрать</span></div>
      </div>
    </div>
  )
}

export default PopupChangePlan;