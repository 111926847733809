import PropTypes from 'prop-types'
import { withRouter } from 'next/router'
import Link from "next/link";

const NavLink = ({to, as, children, router, className,  scroll}) => {
  const cl = className ? className : ''

  const handleClick = () =>  {
    router
      .push(to)
      .then(() => window.scrollTo(0, 0))
  }

  return (
    <Link href={to} as={as} scroll={scroll}>
      <a 
        className={(router.pathname === to || router.pathname === as || router.pathname.includes(to) || router.pathname.includes(as)) ? `${cl} active` : cl}
        onClick={handleClick}
      >{children}</a>
    </Link>
  )
}

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
}

export default withRouter(NavLink)
