import axios from 'axios'

import {newData} from '../mock/press-smi'

/**
 * Constants
 * */
export const moduleName = 'pressSmi'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`
export const CHANGE_LIMIT = `${moduleName}/CHANGE_LIMIT`

const defaultState = {
  response: [],
  limit: 6,
  loaded: false
}

/**
 * Reducer
 * */
export default (state = defaultState, action) => {
  const { type, response, payload } = action

  switch (type) {
    case FETCH_REQUEST:
      return { ...state, response: response.items, loaded: true }
	case CHANGE_LIMIT:
        return {...state, limit: response}
		
    default:
      return state
  }
}

/**
 * Action Creators
 * */
export const fetchPressSmi = (api) => async dispatch => {
  // dispatch({ type: FETCH_REQUEST, response: newData })
  await axios
    .get(api + '/ajax/json.php', { params: {page: 'press', type: 'smi'} })
    .then(({ data }) => data)
    .then(response => {
      dispatch({ type: FETCH_REQUEST, response })
    })
}

export const setLimitSmi = response => dispatch => dispatch({ type: CHANGE_LIMIT, response });