import axios from 'axios'

//import { mock } from '../mock/how-buy'

export const moduleName = 'howbuy'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`

const defaultState = {
  response: [],
  loaded: false
}

export default (state = defaultState, action) => {
  const { type, response } = action

  if(type === FETCH_REQUEST){
    return {
      ...state,
      response,
      loaded: true
    }
  }

  return state
}

export const fetchAll = (api) => async dispatch => {
   await axios
     .get(api + '/ajax/json.php?page=howbuy')
     .then(({ data }) => data)
     .then(response => dispatch({ type: FETCH_REQUEST, response }))
}
