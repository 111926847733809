import UiSelectField from "./filter-fields/UiSelectField";

const FormFieldSelectValid = ({trigger, setValue, defaultValue, options, value, name, valid}) => {

  const create = () => {
    return (
      <UiSelectField
        options={options}
        value={value}
        defaultValue={defaultValue}
        onChange={e => {
          setValue({target: {value: e.target.value, name}})
        }}
      />
    )
  }

  return (
    <div
      className="filter-field"
      error={!value && trigger ? 'true' : 'false'}
    >
      {create()}
    </div>
  )
}

export default FormFieldSelectValid;