import { createSelector } from 'reselect'
import axios from 'axios';
import Cookies from 'js-cookie'

export const moduleName = 'form'
export const OPEN = '_OPEN'
export const CLOSE = '_CLOSE'
export const THX = `${moduleName}/THX`
export const MODAL = `${moduleName}/MODAL`


const defaultState = {
    thx: {
        open: false,
        message: null
    },
    feedback: {
        open: false,
        title: null,
        message: null,
        name: null
    },
    videoCall: {
        open: false,
        title: null,
        message: null
    }
}

export default (state = defaultState, action) => {
    const { type, payload } = action

    switch (type) {
        case THX + OPEN:
            return { ...state, thx: {...state.thx, open: true, message: payload.message}}

        case THX + CLOSE:
            return { ...state, thx: {...state.thx, open: false, message: null}}

        case MODAL + OPEN:
            return { ...state, [payload.type]: {...state[payload.type], open: payload.open ? payload.open : true, title: payload.title, message: payload.message, lk: payload.lk, name: payload.name}}

        case MODAL + CLOSE:
            return { ...state, [payload.type]: {...state[payload.type], open: false, title: null, message: null, name: null}}

        default:
            return state
    }
}


export const openThxMessage = (message) => ({ type: THX + OPEN, payload: {message} })
export const closeThxMessage = () => ({ type: THX + CLOSE })


export const sendRequest = (params, api) => async dispatch => {
    // await ajax
    await axios
        .post((api || '') + '/ajax/json.php?page=feedback', params)
        .then(({ data }) => data)
        .then(response => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push ({
              'event': 'formSuccess',
              'formName': 'formFavorites',
            });
            dispatch(openThxMessage())
        })
}

export const sendReview = (params, api) => async dispatch => {
    // await ajax
    await axios
        .post((api || '') + '/ajax/json.php?page=feedback', params)
        .then(({ data }) => data)
        .then(response => {
            dispatch(openThxMessage())
        })
}

export const openModal = (type, open, title, message, lk, name) => ({ type: MODAL + OPEN, payload: {type, open, title, message, lk, name} })
export const closeModal = (type) => ({ type: MODAL + CLOSE, payload: {type} })

export const sendFeedback = (params, api, name) => async dispatch => {
    //console.log('feedback sent: ', params);
    await axios
        .post((api || '') + '/ajax/json.php?page=feedback', params)
        .then(({ data }) => data)
        .then(response => {
            //console.log(response)
            if(response.error){
                dispatch(openModal('feedback', 'error'))
            }
            else{
              if (name === 'footer') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push ({
                  'event': 'formSuccess',
                  'formName': 'formFooter',
                });
              }
              dispatch(openModal('feedback', 'ok'))
            }
        })
}

export const sendVideoCall = (params, api) => async dispatch => {
    //console.log('feedback sent: ', params);
    await axios
        .post((api || '') + '/ajax/json.php?page=feedback', params)
        .then(({ data }) => data)
        .then(response => {
            //console.log(response)
            if(response.error){
                dispatch(openModal('feedback', 'error'))
            }
            else{
                dispatch(openModal('feedback', 'ok'))
            }
        })
}

export const sendForDeveloper = (params, api) => async dispatch => {
    //console.log('feedback sent: ', params);
    await axios
        .post((api || '') + '/ajax/json.php?page=for_developer_form', params)
        .then(({ data }) => data)
        .then(response => {
            //console.log(response)
            if(response.error){
                dispatch(openModal('feedback', 'error'))
            }
            else{
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push ({
                  'event': 'formSuccess',
                  'formName': 'formDeveloper',
                });
                dispatch(openModal('feedback', 'ok'))
            }
        })
}

export const sendConsulting = (params, api, type) => async dispatch => {
    //console.log('feedback sent: ', params);
    await axios
        .post((api || '') + '/ajax/json.php?page=consulting_form', params)
        .then(({ data }) => data)
        .then(response => {
            //console.log(response)
            if(response.error){
                dispatch(openModal('feedback', 'error'))
            }
            else{
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push ({
                  'event': 'formSuccess',
                  'formName': 'formcConsulting',
                });
                dispatch(openModal('feedback', 'ok'))
            }
        })
}

export const sendFranchise = (params, api, id) => async dispatch => {
    await axios
        .post(`/ajax/json.php?page=franchise-form&PAGE=${id}`, params)
        .then(({ data }) => data)
        .then(response => {
            //console.log(response)
            if(response.error){
                dispatch(openModal('feedback', 'error', 'Ошибка!', response.message || message))
            }
            else{
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push ({
                  'event': 'formSuccess',
                  'formName': 'formcServices',
                });
                dispatch(openModal('feedback', 'ok', 'Спасибо!', response.message || message))
            }
        })
}

export const sendBookingRequest = (params, api, close, title, message, lk) => async dispatch => {

    const PHPSESSID = Cookies.get('PHPSESSID');
    if(PHPSESSID){
        params.append('key', `${PHPSESSID[5]}${PHPSESSID[10]}${PHPSESSID[8]}${PHPSESSID[30]}${PHPSESSID[20]}`)
    }

    await axios
        .post((api || '') + '/ajax/json.php?page=book', params)
        .then(({ data }) => data)
        .then(response => {

            if(response.error){
                //dispatch(openModal('feedback', 'error', 'Спасибо!', response.message || message, lk))
                dispatch(openModal('feedback', 'error', 'Ошибка!', response.message || message, lk))
            }
            else{
                //dispatch(openModal('feedback', 'ok', 'Ошибка!', message, lk))
                dispatch(openModal('feedback', 'ok', 'Спасибо!', response.message || message, lk))
            }

            if(close){
                close();
            }
        })
}

export const sendUniquenessRequest = (params, api) => async dispatch => {
    //console.log('UniquenessRequest sent: ', params);
    await axios
        .post((api || '') + '/ajax/json.php?page=subagent-check', params)
        .then(({ data }) => data)
        .then(response => {
            if(response.error){
                dispatch(openModal('feedback', 'error', 'Повторная заявка!', response.message))
            }
            else{
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push ({
                  'event': 'formSuccess',
                  'formName': 'formcaAgents',
                });
                dispatch(openModal('feedback', 'ok', 'Спасибо, ваша заявка отправлена.', 'Мы проверим клиента на&nbsp;уникальность и&nbsp;ответ пришлем на&nbsp;указанный Вами адрес электронной почты.'))
            }
        })
}

export const sendCooperationRequest = (params, api) => async dispatch => {
    //console.log('CooperationRequest sent: ', params);
    await axios
        .post((api || '') + '/ajax/json.php?page=subagent-coop', params)
        .then(({ data }) => data)
        .then(response => {
            if(response.error){
                dispatch(openModal('feedback', 'error', 'Повторная заявка!', response.message))
            }
            else{
                dispatch(openModal('feedback', 'ok', 'Спасибо, ваша заявка отправлена.', 'Мы проверим клиента на&nbsp;уникальность и&nbsp;ответ пришлем на&nbsp;указанный Вами адрес электронной почты.'))
            }
        })
}
