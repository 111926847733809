import { useState } from 'react'

export const useForm = (formFields) => {

  const [trigger, setTrigger] = useState(false)
  const [fields, setFields] = useState({ ...formFields })
  const [file, setFile] = useState('');

  const formIsValid = () => {
    return Object.keys(fields).every((name) => {
      const field = fields[name]
      if (!field.validate) return true
      return field.valid
    })
  }

  const setFieldValid = (name, valid, valid_reg) => setFields({ ...fields, [name]: { ...fields[name], valid, valid_reg } })

  const setValue = (ev) => {
    const { name, value } = ev.target
    setFields({ ...fields, [name]: { ...fields[name], value } })
  }
  
  const setValueFile = (ev) => {
    const { name, value } = ev.target
    setFile({ ...fields, [name]: { ...fields[name], value } })
  }

  const setCheckValue = (name, value) => {
    setFields({ ...fields, [name]: { ...fields[name], value } })
  }

  const clearForm = () => {
    setFields({ ...formFields })
    setTrigger(false)
  }

  return [fields, setValue, setFieldValid, formIsValid, trigger, setTrigger, setCheckValue, clearForm, file, setValueFile]

}


