import axios from 'axios'

import mockData from '../mock/building';
import mockFlats from '../mock/building-flats';

export const moduleName = 'building'

export const FETCH_DATA = `${moduleName}/FETCH_DATA`

export const fetchData = () => async dispatch => {
  dispatch({ type: FETCH_DATA, response: mockData })
  // await axios
  //   .get(API + 'posts', { params: {} })
  //   .then(({ data }) => data)
  //   .then(response => dispatch({ type: FETCH_REQUEST, response: apartment-booking }))
}

const defaultState = {
  data: null,
}

export default (state = defaultState, action) => {
  const { type, response } = action

  if(type === FETCH_DATA){
    return {
      ...state,
      data: response,
    }
  }

  return state;
}
