import {useState} from 'react'
import {connect, useSelector} from 'react-redux'
import Phone from './phone'
import Link from './link'
import Form from '../form/subscribe-form'
import Social from './social'
import AppStoreLink from './appstore-link'
import GooglePlayLink from './google-play-link'
import {openModal} from '../../redux/modules/form'
import LoadFavorite from './LoadFavorite'
import {menuBottomSelector} from "../../redux/modules/site";
//import { HashLink as Link } from 'react-router-hash-link';

const Footer = ({ firstLoad, mobile, openModal, indexPage }) => {
    const menu = useSelector(menuBottomSelector)
    const [open, setOpen] = useState(null)

    const isOpen = (idx) => !mobile || (mobile && open == idx)

    const toggleOpen = (idx) => open != idx ? setOpen(idx) : setOpen(null)

    const getActive = (idx) => open == idx ? 'true' : 'false'

    const year = new Date().getFullYear();

    const call = () => {
        if(window && window.ComagicWidget) ComagicWidget.openSitePhonePanel();
    }

    return (
        <>
            <LoadFavorite />
            <div className="footer frame">
                <div className="footer__row footer-menu">
                    {Array.isArray(menu) && menu.map((el, i) => {
                        return <div key={el.id}>
                            {mobile && el.children ? <div className="footer-menu__title ico-7" data-active={getActive(i)} onClick={() => toggleOpen(i)}>{el.name}</div>
                                : <div className="footer-menu__title"><Link to={el.url} className="no-border-link"><span>{el.name}</span></Link></div>
                            }
                            {isOpen(i) && el.children && <>
                                {el.children.map(el => <div key={el.id} className="footer-menu__item"><Link to={el.url} className="no-border-link"><span>{el.name}</span></Link></div>)}
                            </>}
                        </div>
                    })}
                </div>
                <div className="footer__row">
                    <div className="footer__left">
                        <Phone />
                        <div className="footer__links">
                            <div className="no-border-link" onClick={call}><span>Заказать звонок</span></div>
                            {/*<Link to="/" className="no-border-link"><span>Записаться в офис</span></Link>*/}
                            <div className="no-border-link" onClick={() => openModal('feedback', true, null, null, null, 'footer')}><span>Обратная связь</span></div>
                        </div>
                    </div>
                    {/*{mobile || <div className="footer__mobile-app">*/}
                    {/*    <div className="footer__mobile-app__qr animate">*/}
                    {/*        <img width='124' height='124' data-src="/static/img/index/qr.png" alt="" />*/}
                    {/*    </div>*/}
                    {/*    <div className="footer__mobile-app__links animate">*/}
                    {/*        <AppStoreLink href="https://apps.apple.com/ru/app/est-a-tet/id1476056814" />*/}
                    {/*        <GooglePlayLink href="https://play.google.com/store/apps/details?id=ru.estatet.android" />*/}
                    {/*    </div>*/}
                    {/*</div>}*/}
                    {mobile || <div className="footer__right">
                        <Form />
                        <div className="footer__social"><Social /></div>
                    </div>}
                </div>
                <div className="footer__row">
                    <div className="footer__left">
                        <div>
                            © 2008&mdash;{year}  Est&#8209;a&#8209;Tet <br/>
                            <a href="/static/personal_data_processing_policy.pdf" target="_blank" className="border-link footer__police"><span>Политика конфиденциальности.</span></a>
                        </div>
                    </div>
                  <div className={'footer__offert'}>
                    Любая  информация, представленная на&nbsp;данном сайте, носит исключительно информационный характер
                    и&nbsp;ни&nbsp;при&nbsp;каких условиях не&nbsp;является публичной  офертой, определяемой положениями статьи 437&nbsp;ГК&nbsp;РФ.
                  </div>
                    <div className="footer__right">
                        Разработано в <a href="https://www.amio.ru/" target="_blank" className="border-link"><span>A M I O</span></a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(
    state => ({mobile: state.mobile}), {openModal}
)(Footer)
