import React, { useState, useEffect } from 'react'
import {connect} from 'react-redux'
import {closeModal} from '../../redux/modules/form'
import { SvgPopupClose } from '../../icons';
import Link from 'next/link';

const Popup = ({children, form, type, closeModal}) => {
  const el = form[type]

  useEffect(() => {
    const body = document.body;
    if(el.open){
      body.classList.add('overflow');
    }
    else{
      body.classList.remove('overflow');
    }

    window.addEventListener('keyup', onKeyup)
    return () => {
        window.removeEventListener('keyup', onKeyup)
    }
  }, [el.open])

  const onKeyup = target => {
    if (target.keyCode === 27 && el.open)  closeModal(type)
  }

  if (!el || !el.open) return null

  if (el.open === 'ok' || el.open === 'error') {
    return(
      <div className="popups fade-in">
        <div className="popups__in">
          <div className="popups__ok">
            <div className="popups__close" onClick={() => closeModal(type)}>
              <SvgPopupClose />
            </div>

            <div className="popups__ok-in">
              <div className="popups__ok-title">{el.title ? el.title : el.open === 'ok' ? 'Спасибо!' : 'Ошибка!'}</div>
              <div className="popups__ok-text">
                {el.open === 'ok' ? <div dangerouslySetInnerHTML={{__html: el.message}} /> || 'Ваша заявка отправлена' : <div dangerouslySetInnerHTML={{__html: el.message}} /> || 'Не удалось отправить, повторите попытку'}
                {el.lk && (
                  <div onClick={() => closeModal(type)}>
                    <Link href="/lk"><a>Перейти в личный кабинет</a></Link>
                  </div>
                )}
              </div>
              <div className="btn btn--border no-border-link" onClick={() => closeModal(type)}><span>Закрыть</span></div>
            </div>
          </div>
        </div>
      </div>
    )

    /*return (
      <div className="popup popup_success">
        <div className="popup__bg" onClick={() => closeModal(type)} />
        <div className="popup__in">
          <div className="popup__close" onClick={() => closeModal(type)} />
          <div className="popup__title">{el.open === 'ok' ? 'Спасибо!' : 'Ошибка!'}</div>
          <div className="popup__text">
              {el.open === 'ok' ? 'Ваша заявка отправлена' : 'Не удалось отправить, повторите попытку'}
          </div>
        </div>
      </div>
    )*/
  }

  return (
    <div className="popup">
      {/*<div className="popup__bg" onClick={() => closeModal(type)} />*/}
      <div className="popup__in">
          <div className="popup__close" onClick={() => closeModal(type)} />
          {children}
      </div>
    </div>
  )
}

export default connect(
  state => ({
    form: state.form
  }),
  {closeModal}
)(Popup)
