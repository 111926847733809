import axios from 'axios'

//import { mock, mockAfter } from '../mock/news'

export const moduleName = 'news'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`
export const FETCH_REQUEST_FILTER = `${moduleName}/FETCH_REQUEST_FILTER`

const defaultState = {
  loaded: false,
  filter: null
}

export default (state = defaultState, action) => {
  const { type, response } = action

  if(type === FETCH_REQUEST){
    return {
      ...state,
      ...response,
      loaded: true
    }
  }

  if(type === FETCH_REQUEST_FILTER){
    return {
      ...state,
      filter: response,
    }
  }

  return state
}

export const fetchAll = (api, params) => async dispatch => {
   await axios
     .get(api + '/ajax/json.php?page=news', { params })
     .then(({ data }) => data)
     .then(response => dispatch({ type: FETCH_REQUEST, response }))
}

export const fetchFilter = (api) => async dispatch => {
   await axios
     .get(api + '/ajax/json.php?page=news_filter')
     .then(({ data }) => data)
     .then(response => dispatch({ type: FETCH_REQUEST_FILTER, response }))
}
