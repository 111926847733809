import axios from 'axios'

import mock from '../mock/apartment-booking'

/**
 * Constants
 * */
export const moduleName = 'apartmentBooking'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`
export const SET_NEWS_FEED_TYPE = `${moduleName}/SET_NEWS_FEED_TYPE`

const defaultState = {
  response: {},
  loaded: false
}

/**
 * Reducer
 * */
export default (state = defaultState, action) => {
  const { type, response, payload } = action

  switch (type) {
    case FETCH_REQUEST:
      return { ...state, response, loaded: true }

    default:
      return state
  }
}

/**
 * Action Creators
 * */
export const fetchAll = () => async dispatch => {
  dispatch({ type: FETCH_REQUEST, response: mock })
  // await axios
  //   .get(API + 'posts', { params: {} })
  //   .then(({ data }) => data)
  //   .then(response => dispatch({ type: FETCH_REQUEST, response: apartment-booking }))
  
}
