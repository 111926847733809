export default [
  {
    id: 1,
    type: 'Главное',
    title: 'Кто и зачем инвестирует в недвижимость в 2020',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/4.jpg'
  },
  {
    id: 2,
    type: 'Главное',
    title: 'ТОП 3 районов для жизни с ребенком. Или есть ли радость жизни в комуналке?',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/1.jpg'
  },
  {
    id: 3,
    type: 'Главное',
    title: 'Как скажется измененение курса доллара на стоимости новостроек?',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/3.jpg'
  },
  {
    id: 4,
    type: 'Новости застройщиков',
    title: 'Опубликован рейтинг агенств недвижимости по итогам работы в 2019 году',
    anonce: 'В статье критерии по которым производился отбор, участники и список с комментариями',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/5.jpg'
  },
  {
    id: 5,
    type: 'Новости застройщиков',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/2.jpg'
  },
  {
    id: 6,
    type: 'Новости застройщиков',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/6.jpg'
  },
  {
    id: 7,
    type: 'Новости застройщиков',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/4.jpg'
  },
  {
    id: 8,
    type: 'Новости застройщиков',
    title: 'Мне, пожалуйста, квартиру не как у всех',
    anonce: 'Устали от однотипных планировок и шаблонных описаний от риелторов? Внутри пара слов о новом сервисе "индивидуальный подбор"',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/1.jpg'
  },
  {
    id: 9,
    type: 'Город',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/3.jpg'
  },
  {
    id: 10,
    type: 'Город',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/5.jpg'
  },
  {
    id: 11,
    type: 'Город',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/2.jpg'
  },
  {
    id: 12,
    type: 'Город',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/6.jpg'
  },
  {
    id: 13,
    type: 'Город',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/4.jpg'
  },
  {
    id: 14,
    type: 'Дизайн',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/1.jpg'
  },
  {
    id: 15,
    type: 'Дизайн',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/3.jpg'
  },
  {
    id: 16,
    type: 'Дизайн',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/5.jpg'
  },
  {
    id: 17,
    type: 'Дизайн',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/2.jpg'
  },
  {
    id: 18,
    type: 'Дизайн',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/6.jpg'
  },
  {
    id: 19,
    type: 'Город',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/4.jpg'
  },
  {
    id: 20,
    type: 'Инвестиции',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/1.jpg'
  },
  {
    id: 23,
    type: 'Инвестиции',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/3.jpg'
  },
  {
    id: 24,
    type: 'Инвестиции',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/5.jpg'
  },
  {
    id: 25,
    type: 'Инвестиции',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/2.jpg'
  },
  {
    id: 26,
    type: 'Инвестиции',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/6.jpg'
  },
  {
    id: 27,
    type: 'Законы',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/1.jpg'
  },
  {
    id: 28,
    type: 'Законы',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/2.jpg'
  },
  {
    id: 29,
    type: 'Законы',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/4.jpg'
  },
  {
    id: 30,
    type: 'Законы',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/4.jpg'
  },
  {
    id: 31,
    type: 'Законы',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/1.jpg'
  },
  {
    id: 32,
    type: 'Аналитика',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/2.jpg'
  },
  {
    id: 33,
    type: 'Аналитика',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/4.jpg'
  },
  {
    id: 34,
    type: 'Аналитика',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/4.jpg'
  },
  {
    id: 4,
    type: 'Главное',
    title: 'Опубликован рейтинг агенств недвижимости по итогам работы в 2019 году',
    anonce: 'В статье критерии по которым производился отбор, участники и список с комментариями',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/2.jpg'
  },
  {
    id: 5,
    type: 'Главное',
    title: 'Задумались о покупке недвижимости?',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/4.jpg'
  },
  {
    id: 51,
    type: 'Главное',
    title: 'Задумались о покупке недвижимости?',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/4.jpg'
  },
  {
    id: 53,
    type: 'Главное',
    title: 'Задумались о покупке недвижимости?',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/2.jpg'
  },
  {
    id: 54,
    type: 'Главное',
    title: 'Задумались о покупке недвижимости?',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/4.jpg'
  },
  {
    id: 35,
    type: 'Спецпредложения',
    title: 'При покупке квартиры в ЖК Плаза машиноместо в подарок на 6 месяцев',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/2.jpg'
  },
  {
    id: 36,
    type: 'Спецпредложения',
    title: 'Скидки в ROZA ROSSA 15% в честь юбилея застройщика', date: 'до 11 июля 2020',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/4.jpg'
  },
  {
    id: 37,
    type: 'Спецпредложения',
    title: '2 + 1 при аренде машиноместа в Залесовском', date: 'до 11 июля 2020',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/4.jpg'
  },
  {
    id: 38,
    type: 'Спецпредложения',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/1.jpg'
  },
  {
    id: 39,
    type: 'Спецпредложения',
    title: 'Повесть о том, как я продал свой дом в Италии, купил квартиру в Москве и остался ни с чем',
    date: '12 января 2020',
    like: 1238,
    img: '/static/img/news-feed/2.jpg'
  }
]