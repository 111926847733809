import {useState} from 'react'
import {connect, useSelector } from 'react-redux'
import { useRouter } from 'next/router';
import {useForm} from '../../hooks/use-form'
import InputField from '../_app/input-field'
import CheckboxField from '../_app/checkbox-field'
import { sendFeedback, sendBookingRequest } from '../../redux/modules/form'

const Feedback = ({sendFeedback, sendBookingRequest, title, api, slug, close, objects, formFeedback}) => {
    const router = useRouter();
    const auth = useSelector(state => state.auth);

    /*
    if(typeof Comagic != 'undefined' && typeof(Comagic.addOfflineRequest) === "function"){
      console.log('test comagic');
      Comagic.addOfflineRequest({name: 'test_name', email: 'test_email', phone: 'test_phone', message: 'test_txt'});
    }

     */

    const getValues = () => {
        //if(slug){
        if(typeof auth == 'object'){
            const data = {};

            if(auth.fio){
                data.fio = auth.fio;
            }
            if(auth.phone){
                data.phone = auth.phone;
            }
            if(auth.email){
                data.email = auth.email;
            }

            return data;
        }
        //}

        return {
            fio: '',
            phone: '',
            email: ''
        }
    }

    const [step, setStep] = useState(1)
    const [smsSend, setSmsSend] = useState(false)
    const [fields, setValue, setFieldValid, formIsValid, trigger, setTrigger, setCheckValue, clearForm] = useForm({
        fio: {
            value: getValues().fio,
            name: 'fio',
            type: 'text',
            placeholder: 'Иванов Иван Иванович',
            validate: 'text',
            valid: getValues().fio || false
        },
        phone: {
            value: getValues().phone,
            name: 'phone',
            type: 'tel',
            placeholder: '+7 (123) 456-78-90',
            validate: 'phone',
            valid: getValues().phone || false,
            valid_reg: false
        },
        email: {
            value: getValues().email,
            name: 'email',
            type: 'email',
            placeholder: 'ivanov@mail.ru',
            //validate: 'email',
            //valid: getValues().email || false
            valid: true
        },
        text: {
            value: '',
            name: 'text',
            type: 'textarea',
            placeholder: '',
            //validate: 'text',
            valid: true
        },
        check: {
            value: false,
            name: 'check',
            valid: false
        }
    })

    const send = async () => {
        setTrigger(true)
        if (!formIsValid() || !fields.check.value) return false

        const fd = new FormData();
        fd.append('FIO', fields.fio.value);
        fd.append('PHONE', fields.phone.value);
        fd.append('EMAIL', fields.email.value);
        fd.append('MESSAGE', fields.text.value);
        fd.append('AGREE', +fields.check.value);
        fd.append('FROM_URL', router.asPath);

        if(objects){
            fd.append('OBJECTS', objects);
        }
        else{
            if(slug){
                if(slug[0]){
                    fd.append('CATALOG', slug[0]);
                }
                if(slug[1]){
                    fd.append('BUILDING_CODE', slug[1]);
                }
                if(slug[2]){
                    fd.append('FLAT_ID', slug[2]);
                }
            }
        }


        if(slug){

            if(slug[2]){
                const message = `Благодарим за обращение в Est-a-Tet, ваша заявка принята! Квартира зарезервирована за вами на 2 часа. В течение этого времени мы актуализируем информацию у застройщика по наличию данного лота и вернемся к вам с обратной связью.`
                sendBookingRequest(fd, api, close, message, true);
                if(typeof gtag != 'undefined'){
                    gtag('event', 'order_flat_send', {'event_category': 'order_flat_send'});
                }
                if(typeof ym != 'undefined'){
                    ym(4831804, 'reachGoal', 'send_flat_order');
                }
            }
            else{
                sendBookingRequest(fd, api, close, '',  true);
                if(typeof gtag != 'undefined'){
                    gtag('event', 'order_send', {'event_category': 'order_send'});
                }
                if(typeof ym != 'undefined'){
                    ym(4831804, 'reachGoal', 'send_order');
                }
            }
        }
        else{
            const name = formFeedback.name
            sendFeedback(fd, api, name)
        }

        if(typeof Comagic != 'undefined' && typeof(Comagic.addOfflineRequest) === "function"){
            //console.log({name: fields.fio.value, email: fields.email.value, phone: fields.phone.value, message: fields.text.value});
            Comagic.addOfflineRequest({name: fields.fio.value, email: fields.email.value, phone: fields.phone.value, message: fields.text.value});
        }

        clearForm()
    }

    const setTitle = () => {
        if(slug){
            if(slug[2]){
                return 'Заявка';
            }

            return 'Заявка на подбор квартиры';
        }else{
            if(title){
                return title;
            }
        }

        return 'Обратная связь';
    }

    return (
        <div className="form-block">
            <div className="popup__title">{setTitle()}</div>

            <div className="input-title">ФИО</div>
            <InputField
                trigger={trigger}
                setFieldValid={setFieldValid}
                setValue={setValue}
                className="filter-field__body"
                {...fields.fio}
            />
            <div className="sign-up-for-central-office__field">
                <div className="input-title">Телефон</div>
                <InputField
                    trigger={trigger}
                    setFieldValid={setFieldValid}
                    setValue={setValue}
                    {...fields.phone}
                />
            </div>
            <div className="input-title">E-mail</div>
            <InputField
                trigger={trigger}
                setFieldValid={setFieldValid}
                setValue={setValue}
                {...fields.email}
            />
            <div className="input-title">Сообщение</div>
            <InputField
                trigger={trigger}
                setFieldValid={setFieldValid}
                setValue={setValue}
                {...fields.text}
            />
            <CheckboxField
                trigger={trigger}
                setFieldValid={setFieldValid}
                setValue={setCheckValue}
                {...fields.check}
            />
            <div className="filter-button"><div className="btn btn--border no-border-link" onClick={send}><span>Отправить</span></div></div>
        </div>
    )
}

export default connect(
  state => ({formFeedback: state.form.feedback}),
{sendFeedback, sendBookingRequest}
)(Feedback)
