import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import axios from "axios";
import FormFieldSelectValid from "../_app/FormFieldSelectValid";
import InputField from "../_app/input-field";
import CheckboxField from "../_app/checkbox-field";
import { setCalcPopup } from "../../redux/modules/calc";
import { SvgPopupClose } from "../../icons";
import { useForm } from "../../hooks/use-form";

const MortgagePopup = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const data = useSelector(state => state.calc.response);
  const popup = useSelector(state => state.calc.popup);
  const [popupCode, setPopupCode] = useState(false);
  const [code, setCode] = useState(null);

  const [fields, setValue, setFieldValid, formIsValid, trigger, setTrigger, setCheckValue, clearForm] = useForm({
    surname: {
      value: "",
      name: "surname",
      type: "text",
      placeholder: "Иванов",
      validate: "text",
      valid: false,
    },
    name: {
      value: "",
      name: "name",
      type: "text",
      placeholder: "Иван",
      validate: "text",
      valid: false,
    },
    patronymic: {
      value: "",
      name: "patronymic",
      type: "text",
      placeholder: "Иванович",
      validate: "text",
      valid: false,
    },
    phone: {
      value: "",
      name: "phone",
      type: "tel",
      placeholder: "+7 (123) 456-78-90",
      validate: "phone",
      valid: false,
      valid_reg: false,
    },
    select: {
      value: 0,
      name: "select",
      valid: false,
    },
    check: {
      value: true,
      name: "check",
      valid: true,
    },
  });


/*  let sms = {
    value: '',
    name: 'sms',
    type: 'text',
    placeholder: 'Код',
    validate: 'sms',
    valid: false
  };*/

  useEffect(() => {
    if (popup) {
      const scroll = window.scrollY;
      document.querySelector("body").setAttribute("data-scroll", scroll);
      document
        .querySelector("body")
        .setAttribute(
          "style",
          "overflow: hidden; padding-right: " + scrollbarWidth() + "px;"
        );
    }
    window.addEventListener("keyup", onKeyup);
    return () => {
      window.removeEventListener("keyup", onKeyup);
    };
  }, [popup]);

  useEffect(() => {
    setValue({ target: { name: "select", value: 0 } });
    if (data) {
      if (data.buildings) {
        if (router.query.slug) {
          const code = router.query.slug[1];
          const zhk = data.buildings.filter((item) => item.code == code)[0];

          if (zhk) {
            setValue({ target: { name: "select", value: zhk.value } });
          }
        }
      }
    }
  }, [router.query]);

  if (!data || !popup) return null;

  const apply = (code_sms) => {

    setTrigger(true);
    if (!formIsValid() || !fields.check.value || !fields.select.value)
      return false;

    const fd = new FormData();

    fd.append("surname", fields.surname.value);
    fd.append("name", fields.name.value);
    fd.append("patronymic", fields.patronymic.value);
    fd.append("phone", fields.phone.value);
    fd.append("object_id", fields.select.value);
    if(code_sms){
      fd.append("code", code_sms);
    }

    axios
      .post("/ajax/json.php?page=mortgage-form", fd)
      .then(({ data }) => data)
      .then((response) => {

        if (response.redirect) {
          dispatch(setCalcPopup("ok"));
          setPopupCode(false);
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push ({
            'event': 'formSuccess',
            'formName': window.location.pathname === '/mortgage' ? 'formcMortgage' : 'formResidential',
          });
          window.open(response.redirect);
        } else {

          if(response?.message === "code_required"){
            fields.sms =  {
              value: '',
              name: 'sms',
              type: 'text',
              placeholder: '',
              validate: 'text',
              valid: false
            };
            setPopupCode(true);

          }else{
            setPopupCode(false);
            dispatch(setCalcPopup("error"));
          }
        }
        if(typeof Comagic != 'undefined' && typeof(Comagic.addOfflineRequest) === "function"){
          Comagic.addOfflineRequest({name: fields.name.value, phone: fields.phone.value, message: fields.select.value});
        }
        if(response?.message !== "code_required"){
          clearForm()
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(setCalcPopup("error"));
        clearForm();
      });
  };

  const applyCode = () => {
    setTrigger(true);
    if (!formIsValid() || !fields.check.value || !fields.select.value)
      return false;
    setCode(fields.sms.value);
    apply(fields.sms.value);
  };

  const onKeyup = (target) => {
    if (target.keyCode === 27 && popup) {
      onCloseModal();
      onCloseModalCode();
    }
  };

  const scrollbarWidth = () => {
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.overflow = "scroll";
    outer.style.msOverflowStyle = "scrollbar";
    document.body.appendChild(outer);
    const inner = document.createElement("div");
    outer.appendChild(inner);
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
  };

  const onCloseModal = () => {
    document.querySelector("body").removeAttribute("style");
    window.scrollTo(
      0,
      document.querySelector("body").getAttribute("data-scroll")
    );
    dispatch(setCalcPopup(false));
  };

  const onCloseModalCode = () => {
    setPopupCode(false);
    delete fields['sms'];
  };

  if (popup === "ok" || popup === "error") {
    return (
      <div className="popups fade-in">
        <div className="popups__in">
          <div className="popups__ok">
            <div className="popups__close" onClick={() => onCloseModal()}>
              <SvgPopupClose />
            </div>

            <div className="popups__ok-in">
              <div className="popups__ok-title">
                {popup === "ok" ? "Спасибо!" : "Ошибка!"}
              </div>
              <div className="popups__ok-text">
                {popup === "ok"
                  ? "Ваша заявка отправлена"
                  : "Не удалось отправить, повторите попытку"}
              </div>
              <div
                className="btn btn--border no-border-link"
                onClick={() => onCloseModal()}
              >
                <span>Закрыть</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const buildingsData = data.buildings.filter((item) => item.form);
  //console.log(buildingsData);
  return (
    <>
      <div className="popup">
        <div className="popup__in">
          <div className="popup__close" onClick={() => onCloseModal()}/>
          <div className="popup__title">
            Анкета клиента
            <div>на ипотечный кредит</div>
          </div>

          <div className="form-block">
            <div className="input-title">Фамилия</div>
            <InputField
              trigger={trigger}
              setFieldValid={setFieldValid}
              setValue={setValue}
              className="filter-field__body"
              {...fields.surname}
            />
            <div className="input-title">Имя</div>
            <InputField
              trigger={trigger}
              setFieldValid={setFieldValid}
              setValue={setValue}
              className="filter-field__body"
              {...fields.name}
            />
            <div className="input-title">Отчество</div>
            <InputField
              trigger={trigger}
              setFieldValid={setFieldValid}
              setValue={setValue}
              className="filter-field__body"
              {...fields.patronymic}
            />
            <div className="sign-up-for-central-office__field">
              <div className="input-title">Телефон</div>
              <InputField
                trigger={trigger}
                setFieldValid={setFieldValid}
                setValue={setValue}
                {...fields.phone}
              />
            </div>
            <div className="input-title">Выбрать ЖК</div>
            <FormFieldSelectValid
              trigger={trigger}
              setFieldValid={setFieldValid}
              setValue={setValue}
              {...fields.select}
              options={buildingsData}
            />

            <CheckboxField
              trigger={trigger}
              setFieldValid={setFieldValid}
              setValue={setCheckValue}
              {...fields.check}
            />
          </div>

          <div className="filter-button">
            <div className="btn btn--border no-border-link" onClick={()=>{apply()}}>
              <span>Отправить</span>
            </div>
          </div>
        </div>
      </div>

      {popupCode &&
        <div className="popup fade-in">
          <div className="popup__in">
            <div className="popup__close" onClick={() => onCloseModalCode()}/>
            <div className="popup__title">
              Введите код, отправленный на&nbsp;номер телефона <span className={'nowrap'}>{fields.phone.value}</span>
            </div>


            <div className="form-block">
              <div className="input-title">Код</div>
              <InputField
                trigger={trigger}
                setFieldValid={setFieldValid}
                setValue={setValue}
                className="filter-field__body"
                {...fields.sms}
              />
            </div>
            <div className="filter-button">
              <div className="btn btn--border no-border-link" onClick={()=>{applyCode()}}>
                <span>Отправить</span>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default MortgagePopup;
