import { useState, useEffect } from 'react'
import { useSelector} from 'react-redux'

export const usePageLoading = home => {
  const [logoLoaded, setLogoLoaded] = useState(false)
  const [logoAnimated, setLogoAnimated] = useState(true)
  const loading = useSelector(state => state.loading)

  useEffect(() => {
    if(home){
      let id = null
      const img = new Image()
      img.src = '/static/img/logo.svg'
      img.onload = () => {
        setLogoLoaded(true)
        id = setTimeout(() => setLogoAnimated(false), 1500)
      } 
      return () => clearTimeout(id)
    }
    else{
      setLogoLoaded(true)
      setLogoAnimated(false)
    }
  }, [])



  return [loading, logoAnimated, logoLoaded]
}
