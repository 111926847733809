import axios from 'axios'
// import mock from '../mock/services'

/**
 * Constants
 * */
export const moduleName = 'services'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`

const defaultState = {
  response: {},
  loaded: false
}

/**
 * Reducer
 * */
export default (state = defaultState, action) => {
  const { type, response, payload } = action

  switch (type) {
    case FETCH_REQUEST:
      return { ...state, response: response,  loaded: false }

    //case SET_ACTIVE_TAB_SERVICES:
    //  return { ...state, tab: response }

    default:
      return state
  }
}

/**
 * Action Creators
 * */
export const fetchAll = (api) => async dispatch => {
  // dispatch({ type: FETCH_REQUEST, response: mock })
  await axios
    .get(api + '/ajax/json.php', { params: {page: 'services'} })
    .then(({ data }) => data)
    .then(response => dispatch({ type: FETCH_REQUEST, response }))
}
