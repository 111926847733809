import { useState } from 'react'

export const useTabs = (value, scrollElement) => {
  const [active, setActive] = useState(value)

  const setTab = ev => {
    setActive(+ev.currentTarget.id)
    if (scrollElement) {
      //scrollElement.scrollIntoView({behavior: "smooth"})
    }
    if (typeof scrollElement === 'undefined') {
      //ev.currentTarget.parentNode.scrollIntoView({behavior: "smooth"})
    }
  }

  const toggleTab = ev => {
    const isActive = active === +ev.currentTarget.id
    setActive(isActive ? null : +ev.currentTarget.id)
    if (isActive) return false
    if (scrollElement) {
      //scrollElement.scrollIntoView({behavior: "smooth"})
    } 
    if (typeof scrollElement === 'undefined') {
      //ev.currentTarget.parentNode.scrollIntoView({behavior: "smooth"})
    }
  }

  const setTabById = id => {
    setActive(id)
    // ev.currentTarget.parentNode.scrollIntoView({behavior: "smooth"})
  }

  return {active, setTab, setTabById, toggleTab}

}


