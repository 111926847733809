//import {redirect_arr} from "./redux/mock/redirect_arr";
import axios from "axios";
import getConfig from "next/config";
import Router from "next/router";
import {FETCH_REQUEST} from "./redux/modules/press-smi";

export const declOfNum = (number, titles) => {
    var cases = [2, 0, 1, 1, 1, 2]
    return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
}
export const distinct = (items, prop) => {
    const unique = []
    const distinctItems = []

    items.map((item) => {
        if (!unique[item[prop]]) distinctItems.push(item)

        return (unique[item[prop]] = 1)
    })

    return distinctItems
}
export const compareArrays = (a1, a2) => a1.length==a2.length && a1.every((v,i)=>a2.includes(v))

export const redir = (res, req) => async() => {
    const { publicRuntimeConfig } = getConfig();
    const { api } = publicRuntimeConfig

    await axios.get(api+'/ajax/json.php?page=redirects')
        .then(function (response) {
            const redirect_arr = response.data;

            if (redirect_arr && req) {
                let filtered_redirects = redirect_arr.filter(item => item.from === req.originalUrl)[0];

                if (filtered_redirects){
                    if(res && res.redirect){
                        res.redirect(filtered_redirects.to, '301');
                    }else{
                        //Router.push(filtered_redirects.to);
                    }
                }
            }
        })
        .catch(function (error) {

        })
        .then(function () {

        });
}
export const redir_n = (res, req, redirects) => {
  let n_req = {};
  if(!req?.originalUrl){
    n_req.originalUrl = Router.asPath;
  }else{
    n_req = req;
  }
  if (redirects) {
    let filtered_redirects = redirects.filter(item => item.from === n_req.originalUrl)[0];

    if (filtered_redirects){
      if(res && res.redirect){
        res.redirect(filtered_redirects.to, '301');
      }else{
        //Router.push(filtered_redirects.to);
      }
    }
  }
}


// export const objectToMap = (object, prop) => {
//   return object.reduce((acc, value) => {
//     const oldValue = acc[value[prop]] ? acc[value[prop]] : []
//     return {
//       ...acc,
//       [value[prop]]: [...oldValue, value]
//     }
//   }, {})
// }
// export const arrayToMap = (object, prop) => {
//   return object.reduce((acc, value) => {
//     return {
//       ...acc,
//       [value[prop]]: value
//     }
//   }, {})
// }

// export const compare = (a, b) => (a < b ? -1 : a > b ? 1 : 0)

// export const distinctProps = (items, prop) => {
//   const unique = []
//   const distinctProps = []

//   items.map((item) => {
//     if (!unique[item[prop]]) distinctProps.push(item[prop])

//     return (unique[item[prop]] = 1)
//   })

//   return distinctProps
// }

// export const distinctLabel = (items, prop) => {
//   const unique = []
//   const distinctItems = []

//   items.map((item) => {
//     if (!unique[item[prop].label]) distinctItems.push(item)

//     return (unique[item[prop].label] = 1)
//   })

//   return distinctItems
// }

// export const isInt = (n) => Number(n) === +n

// export const sortingTable = (items, sort) => {
//     // return items

//   return items.sort((a, b) => {
//     const a_s = sort.field === 'floor' && a[sort.field].indexOf(',') > -1 ? a[sort.field].replace(',', '.') : a[sort.field]
//     const b_s = sort.field === 'floor' && b[sort.field].indexOf(',') > -1 ? b[sort.field].replace(',', '.') : b[sort.field]
//     if (sort.field === 'price') {
//       if (+a_s === +b_s) return 0
//       if (+a_s === 0) return 1
//       if (+b_s === 0) return -1
//     }
//     if (isInt(a_s)) {
//       return sort.order === 'desc'
//         ? +a_s > +b_s
//           ? 1
//           : +b_s > +a_s
//           ? -1
//           : 0
//         : +a_s < +b_s
//         ? 1
//         : +b_s < +a_s
//         ? -1
//         : 0
//     } else {
//       return sort.order === 'desc'
//         ? a_s.localeCompare(b_s)
//         : b_s.localeCompare(a_s)
//     }
//   })
// }

