import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector } from 'react-redux';
import { SvgSelectArrow } from '../../../icons';

const UiSelectField = ({options, defaultValue, value, onChange, title}) => {

  const mobile = useSelector(state => state.mobile);

  const arrows = {
    'up': ' ↑',
    'down': ' ↓'
  }

  if(mobile){
    return (
      <div className="ui-select">
        {title && <div className="filter-field__title">{title}</div>}
        <Select
          value={value}
          onChange={onChange}
          
          IconComponent={SvgSelectArrow}
          native
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
        >
          {options.map((item, index) => <option key={`item${index}`} value={item.value}>{item.name + (arrows[item.arrow] ? arrows[item.arrow] : '')}</option>)}
        </Select>
      </div>
    )
  }

  return (
    <div className="ui-select">
      {title && <div className="filter-field__title">{title}</div>}
      <Select
        value={value}
        onChange={onChange}
        IconComponent={SvgSelectArrow}
        defaultValue={270229727}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
      >
        {options.map((item, index) => <MenuItem key={`item${index}`} value={item.value} data-arrow={item.arrow}>{item.name}</MenuItem>)}
      </Select>
    </div>
  )
}

export default UiSelectField;