import axios from 'axios'

import mock from '../mock/index'

/**
 * Constants
 * */
export const moduleName = 'index'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`
export const SET_NEWS_FEED_TYPE = `${moduleName}/SET_NEWS_FEED_TYPE`
export const SET_MORTGAGE_TAB = `${moduleName}/SET_MORTGAGE_TAB`

const defaultState = {
  response: {},
  loaded: false,
  mortgageTab: 1
}

/**
 * Reducer
 * */
export default (state = defaultState, action) => {
  const { type, response, payload } = action

  switch (type) {
    case FETCH_REQUEST:
      return { ...state, response, loaded: true }

    case SET_MORTGAGE_TAB:
      return { ...state, mortgageTab: response }

    default:
      return state
  }
}

/**
 * Action Creators
 * */
export const fetchAll = (api) => async dispatch => {
  await axios
      .get(api + '/ajax/json.php', { params: {page: 'mainpage'} })
      .then(({ data }) => data)
      .then(response => dispatch({ type: FETCH_REQUEST, response }))
}

export const setMortgageTab  = response => dispatch => {
  dispatch({ type: SET_MORTGAGE_TAB, response })
}
