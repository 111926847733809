import axios from "axios";

/**
 * Constants
 * */
export const LOADING = 'LOADING'
export const IS_MOBILE = 'IS_MOBILE'
export const FIRST_LOAD = 'FIRST_LOAD'
export const SITE_DATA = 'SITE_DATA'
export const POPUP_PHOTO = 'POPUP_PHOTO'
export const POPUP_OK = 'POPUP_OK'
export const SAVE_SEARCH = 'SAVE_SEARCH'
export const POPUP_MESSAGE = 'POPUP_MESSAGE'
export const SET_POPUP_CHANGE_PLAN = 'SET_POPUP_CHANGE_PLAN'
export const SELECT_POPUP_CHANGE_PLAN = 'SELECT_POPUP_CHANGE_PLAN'
export const CLEAR_POPUP_CHANGE_PLAN = 'CLEAR_POPUP_CHANGE_PLAN'
export const SET_AUTH = 'SET_AUTH'
export const HOME_SELECT_FIRST_LOAD = 'HOME_SELECT_FIRST_LOAD'
export const LOAD_MENU_TOP = 'LOAD_MENU_TOP'
export const LOAD_MENU_BOTTOM = 'LOAD_MENU_BOTTOM'
export const LOAD_MENU_MAIN = 'LOAD_MENU_MAIN'
export const LOAD_REDIRECTS = 'LOAD_REDIRECTS'

/**
 * Reducer
 * */

export const authReducer = (auth = false, action) => {
  return action.type === SET_AUTH ? action.response : auth
}

export const mobileReducer = (mobile = false, action) => {
  return action.type === IS_MOBILE ? action.mobile : mobile
}

export const loadingReducer = (loading = false, action) => {
  return action.type === LOADING ? action.loading : loading
}

export const firstLoadReducer = (state = true, action) => {
  return action.type === FIRST_LOAD ? false : state
}

export const popupPhoto = (state = null, action) => {
  return action.type === POPUP_PHOTO ? action.response : state
}

export const popupOk = (state = null, action) => {
  return action.type === POPUP_OK ? action.response : state
}

export const saveSearch = (state = null, action) => {
  return action.type === SAVE_SEARCH ? action.response : state
}

export const homeSelectFirstLoad = (state = false, action) => {
  return action.type === HOME_SELECT_FIRST_LOAD ? action.response : state
}

export const popupMessage = (state = {title: null, text: null}, action) => {
  const { type, response } = action

  if(type === POPUP_MESSAGE){
    return {
      ...state,
      ...response
    }
  }
  else{
    return state
  }
}


export const popupChangePlan = (state = {data: null, name: null, select: []}, action) => {
  const { type, response } = action

  if(type === SET_POPUP_CHANGE_PLAN){
    return {
      ...state,
      data: response,
    }
  }
  if(type === SELECT_POPUP_CHANGE_PLAN){
    return {
      ...state,
      select: response,
    }
  }
  if(type === CLEAR_POPUP_CHANGE_PLAN){
    return {
      ...state,
      data: null,
      name: null,
      select: []
    }
  }
  else{
    return state
  }
}

export const menuReducer = (state = {
  menuTopLoaded: false,
  menuTop: [],
  menuBottomLoaded: false,
  menuBottom: [],
  menuMainLoaded: false,
  menuMain: []
}, action) => {
  const { type, response } = action

  if(type === LOAD_MENU_TOP){
    return {
      ...state,
      menuTopLoaded: true,
      menuTop: response
    }
  }
  if(type === LOAD_MENU_BOTTOM){
    return {
      ...state,
      menuBottomLoaded: true,
      menuBottom: response
    }
  }
  if(type === LOAD_MENU_MAIN){
    return {
      ...state,
      menuMainLoaded: true,
      menuMain: response
    }
  }
  else{
    return state
  }
}

export const redirectsReducer = (state = {
  redirectsLoaded: false,
  redirects: [],
}, action) => {
  const { type, response } = action

  if(type === LOAD_REDIRECTS){
    return {
      ...state,
      redirectsLoaded: true,
      redirects: response
    }
  }
  else{
    return state
  }
}

export const menuTopSelector = state => state.menu.menuTop
export const menuBottomSelector = state => state.menu.menuBottom
export const menuMainSelector = state => state.menu.menuMain
export const redirectsSelector = state => state.redirects


/**
 * Action Creators
 * */
export const setAuth = response => ({ type: SET_AUTH, response })

export const loading = (loading) => ({ type: LOADING, loading })

export const isMobile = (mobile) => ({ type: IS_MOBILE, mobile })

export const fetchSiteData = () => ({ type: SITE_DATA })

export const setFirstLoad = (menu) => ({ type: FIRST_LOAD })

export const setPopupPhoto = data => ({ type: POPUP_PHOTO, response: data })

export const setPopupOk = data => ({ type: POPUP_OK, response: data })
export const setSaveSearch = data => ({ type: SAVE_SEARCH, response: data })
export const setPopupMessage = response => ({ type: POPUP_MESSAGE, response })

export const setPopupChangePlan = response => ({ type: SET_POPUP_CHANGE_PLAN, response })
export const selectPopupChangePlan = response => ({ type: SELECT_POPUP_CHANGE_PLAN, response })
export const clearPopupChangePlan = () => ({ type: CLEAR_POPUP_CHANGE_PLAN })

export const setHomeSelectFirstLoad = response => ({ type: HOME_SELECT_FIRST_LOAD, response })

export const fetchMenuMain = (api) => async dispatch => {
  await axios
    .get(api + '/ajax/json.php', { params: {page: 'main_menu'} })
    .then(({ data }) => data)
    .then(response => dispatch({ type: LOAD_MENU_MAIN, response }))
}
export const fetchMenuBottom = (api) => async dispatch => {
  await axios
    .get(api + '/ajax/json.php', { params: {page: 'bottom_menu'} })
    .then(({ data }) => data)
    .then(response => dispatch({ type: LOAD_MENU_BOTTOM, response }))
}
export const fetchMenuTop = (api) => async dispatch => {
  await axios
    .get(api + '/ajax/json.php', { params: {page: 'top_menu'} })
    .then(({ data }) => data)
    .then(response => dispatch({ type: LOAD_MENU_TOP, response }))
}

export const fetchRedirects = (api) => async dispatch => {
  await axios
    .get(api + '/ajax/json.php', { params: {page: 'redirects'} })
    .then(({ data }) => data)
    .then(response => dispatch({ type: LOAD_REDIRECTS, response }))


/*  await axios.get(api+'/ajax/json.php?page=redirects')
    .then(function (response) {
      const redirect_arr = response.data;
      if (redirect_arr && req) {
        let filtered_redirects = redirect_arr.filter(item => item.from === req.originalUrl)[0];
        if (filtered_redirects){
          if(res && res.redirect){
            res.redirect(filtered_redirects.to, '301');
          }else{
            //Router.push(filtered_redirects.to);
          }
        }
      }
    })
    .catch(function (error) {
    })
    .then(function () {
    });*/
}
