import React, {useEffect, useState} from 'react';
import {SvgClose} from "../../icons";
import Cookies from 'js-cookie'

const Cookie = () => {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    const cookie = Cookies.get('cookie');
    if (!cookie) setTimeout(() => setOpen(true), 2000)
  }, [])

  const close = () => {
    Cookies.set('cookie', 1);
    setOpen(false)
  }

  if (!open) return null

  return (
    <div className="cookie-info">
      <div className="cookie-info__close" onClick={close}><SvgClose /></div>
      Мы используем cookies, чтобы сайт работал лучше. <br/>
      Оставаясь на сайте, вы соглашаетесь с <a href="https://www.estatet.ru/static/personal_data_processing_policy.pdf" target="_blank">политикой конфиденциальности</a>.
    </div>
  );
};

export default Cookie;