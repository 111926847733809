import axios from 'axios';
import {useForm} from '../../hooks/use-form'
import { useDispatch } from 'react-redux';
import InputField from '../_app/input-field'
import { setPopupMessage } from '../../redux/modules/site'

const Form = ({placeholder, btnText, api, news, promo}) => {
  const dispatch = useDispatch();

  const [fields, setValue, setFieldValid, formIsValid, trigger, setTrigger, setCheckValue, clearForm] = useForm({
    email: {
      value: '',
      name: 'email',
      type: 'email',
      placeholder: placeholder ? placeholder : 'Введите ваш e-mail',
      validate: 'email',
      valid: false
    }
  })

  const send = () => {
    setTrigger(true)
    if (!formIsValid()) return false

    const fd = new FormData();
    fd.append('email', fields.email.value);

    let maillist = ['news', 'actions']
    if(news){
      maillist = ['news']
    }
    if(promo){
      maillist = ['actions']
    }

    maillist.forEach(item => {
      fd.append('maillist[]', item);
    });

    axios
    .post((api || '') + '/ajax/json.php?page=subscribe', fd)
    .then(({ data }) => data)
    .then(response => {
      if(response.error){
        dispatch(setPopupMessage({title: 'Ошибка', text: 'Что-то пошло не так.<br>Пожалуйста повторите попытку позже'}));
      }
      else{
        dispatch(setPopupMessage({title: 'Спасибо!', text: 'Вы успешно подписались на рассылку новостей'}));

        if(typeof Comagic != 'undefined' && typeof(Comagic.addOfflineRequest) === "function"){
          Comagic.addOfflineRequest({name: fields.email.value, email: fields.email.value});
        }
      }
      clearForm();
    })
  }

  return (
    <div className="subscribe-form">
      <InputField
        trigger={trigger}
        setFieldValid={setFieldValid}
        setValue={setValue}
        {...fields.email}
      />
      <div className="btn btn--border subscribe-form__btn" onClick={send}><span>{btnText ? btnText : 'Подписаться на рассылку'}</span></div>
    </div>
  )
}

export default Form
