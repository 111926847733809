export default [
  {
    id: 1,
    price_min: 1200000,
    price_max: 2000000,
    rooms: 1,
    name: 'ЖК Южный',
    address: 'Москва',
  },
  {
    id: 2,
    price_min: 1900000,
    price_max: 2900000,
    rooms: 2,
    name: 'ЖК Aquatoria',
    address: 'Санкт-Петербург'
  }
]