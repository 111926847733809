import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { setSaveSearch } from '../../redux/modules/site';

const FileIocn = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const saveSearch = useSelector(state => state.saveSearch);
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const url = window.localStorage.getItem('saveSearch');

    if(url){
      dispatch(setSaveSearch(url));
    }
  },[]);

  const to = () => {
    const url = window.localStorage.getItem('saveSearch');
    if(url){
      window.open(url, '_blank')
    }
    else{
      router.push('/catalog_new');
    }
  }
  return (
    <div
      className={saveSearch ? 'file-icon ico-17 red' : 'file-icon ico-17'}
      onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}
      onClick={to}
    >
      {open && <div className="info-bubble">Сохраненный список параметров</div>}
    </div>
  )
}

export default FileIocn