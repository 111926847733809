import axios from 'axios'
import {newData} from '../mock/consulting'
import {SET_ACTIVE_TAB_SERVICES} from "./services";

/**
 * Constants
 * */
export const moduleName = 'consulting'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`

const defaultState = {
  response: {},
  loaded: false
}

/**
 * Reducer
 * */
export default (state = defaultState, action) => {
  const { type, response, payload } = action

  switch (type) {
    case FETCH_REQUEST:
      return { ...state, response, loaded: true }

    default:
      return state
  }
}
/**
 * Action Creators
 * */
export const fetchAll = (api) => async dispatch => {
  await axios
    .get(api + '/ajax/json.php', { params: {page: 'consulting'} })
    .then(({ data }) => data)
    .then(response => dispatch({ type: FETCH_REQUEST, response }))
}
