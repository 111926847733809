import axios from 'axios'

//import mockFilter from '../mock/apartment-search-filter'
//import mockData from '../mock/apartment-search-data.js';

export const moduleName = 'apartmentSearch'

export const FETCH_FILTER = `${moduleName}/FETCH_FILTER`
export const FETCH_DATA = `${moduleName}/FETCH_DATA`
export const FETCH_DATA_MAP = `${moduleName}/FETCH_DATA_MAP`
export const CHANGE_TAB = `${moduleName}/CHANGE_TAB`
export const SET_FILTER_VALUE = `${moduleName}/SET_FILTER_VALUE`;
export const FETCH_DATA_PENDING = `${moduleName}/FETCH_DATA_PENDING`
export const FETCH_DATA_MAP_PENDING = `${moduleName}/FETCH_DATA_MAP_PENDING`

export const fetchFilter = (api, query) => async dispatch => {
  await axios
     .get(api + '/ajax/json.php', {
        params: {
          page: 'apartment-search-filter',
          ...query
        }
      })
     .then(({ data }) => data)
     .then(response => dispatch({ type: FETCH_FILTER, response }))
}

export const fetchData = (api, params) => async dispatch => {

  dispatch({ type: FETCH_DATA_PENDING });

  if(params){
    params.append('pp', 16)
  }

  await axios
    .post(api + '/ajax/json.php?page=apartment-search-data', params)
    .then(({ data }) => data)
    .then(response => dispatch({ type: FETCH_DATA, response }))
    .catch(error => {})
}

export const fetchDataOnFirstLoad = (api, catalog, page) => async dispatch => {
  let page_params = '';
  if(page){
    page_params = '&pg='+page;
  }
  await axios
    .post(`${api}/ajax/json.php?page=apartment-search-data&catalog=${catalog}&view=1&pp=16${page_params}`)
    .then(({ data }) => data)
    .then(response => dispatch({ type: FETCH_DATA, response }))
    .catch(error => {})
}

export const fetchDataRecommend = (api, id) => async dispatch => {
  await axios
    .post(api + '/ajax/json.php', { params: {page: 'apartment-search-data', id} })
    .then(({ data }) => data)
    .then(response => dispatch({ type: FETCH_DATA, response }))
    .catch(error => {
      console.log(error)
    })
}

export const fetchDataMap = (api, params) => async dispatch => {
  dispatch({ type: FETCH_DATA_MAP_PENDING });

  await axios
    .post(api + '/ajax/json.php?page=apartment-search-map', params)
    .then(({ data }) => data)
    .then(response => dispatch({ type: FETCH_DATA_MAP, response }))
    .catch(error => {
      console.log(error)
    })
}
export const fetchDataMapFirstLoad = (api, params) => async dispatch => {
  dispatch({ type: FETCH_DATA_MAP_PENDING });

  await axios
    .post(api + '/ajax/json.php?page=apartment-search-map', params)
    .then(({ data }) => data)
    .then(response => dispatch({ type: FETCH_DATA_MAP, response }))
    .catch(error => {
      console.log(error)
    })
}


export const fetchRecommended = (api, code, view) => async dispatch => {
  await axios
    .post(`${api}/ajax/json.php?page=apartment-search-data&collection=${code}&view=${view}&pp=16`)
    .then(({ data }) => data)
    .then(response => dispatch({ type: FETCH_DATA, response }))
    .catch(error => {})
}
export const fetchRecommendedMap = (api, code) => async dispatch => {
  await axios
    .post(`${api}/ajax/json.php?page=apartment-search-map&collection=${code}`)
    .then(({ data }) => data)
    .then(response => dispatch({ type: FETCH_DATA_MAP, response }))
    .catch(error => {})
}
export const changeTab = response => dispatch => dispatch({ type: CHANGE_TAB, response });
export const setFilterValue = response => dispatch => dispatch({ type: SET_FILTER_VALUE, response });

const defaultState = {
  response: null,
  filter: null,
  loaded: false,
  tab: 0,
  values: null,
  mapData: null,
  pendingList: false,
  pendingMap: false,
  seo: null
}

export default (state = defaultState, action) => {
  const { type, response } = action

  if(type === FETCH_FILTER){
    return {
      ...state,
      ...response,
      loaded: true
    }
  }

  if(type === FETCH_DATA_PENDING){
    return {
      ...state,
      pendingList: true
    }
  }

  if(type === FETCH_DATA){
    return {
      ...state,
      ...response,
      loaded: true,
      pendingList: false
    }
  }

  if(type === CHANGE_TAB){
    return {
      ...state,
      tab: response,
    }
  }

  if(type === SET_FILTER_VALUE){
    return {
      ...state,
      values: response,
    }
  }

  if(type === FETCH_DATA_MAP_PENDING){
    return {
      ...state,
      pendingMap: true,
    }
  }

  if(type === FETCH_DATA_MAP){
    return {
      ...state,
      mapData: response,
      pendingMap: false,
    }
  }

  return state;
}
