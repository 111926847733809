import axios from 'axios'

import mock from '../mock/index'

/**
 * Constants
 * */
export const moduleName = 'articles'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`

const defaultState = {
    response: {},
    loaded: false,
    detail: {}
}

/**
 * Reducer
 * */
export default (state = defaultState, action) => {
    const { type, response } = action

    switch (type) {
        case FETCH_REQUEST:
            return { ...state, response, loaded: true }
        default:
            return state
    }
}

/**
 * Action Creators
 * */
export const fetchArticles = (api) => async dispatch => {
    await axios
        .get(api + '/ajax/json.php', { params: {page: 'articles', count: 'all'} })
        .then(({ data }) => data)
        .then(response => dispatch({ type: FETCH_REQUEST, response }))
}
