import axios from 'axios'

export const moduleName = 'recent'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`

/**
 * Reducer
 * */
export default (state = null, action) => {
  const { type, response } = action

  switch (type) {
    case FETCH_REQUEST:
      return { ...state, ...response }

    default:
      return state
  }
}

export const fetchAll = () => async dispatch => {
  await axios
    .get('/ajax/json.php', { params: {page: 'recent-list'} })
    .then(({ data }) => data)
    .then(response => {
      if(response.flats && response.objects){
        dispatch({ type: FETCH_REQUEST, response })
      }
    })
  
}


