import axios from 'axios'
import * as Sentry from "@sentry/nextjs";
import mock from '../mock/article-detail-complex';

/**
 * Constants
 * */
export const moduleName = 'articlesdetail'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`
export const ERR = `${moduleName}/ERR`

const defaultState = {
  response: [],
  loaded: false,
  error: false
}

/**
 * Reducer
 * */
export default (state = defaultState, action) => {
  const { type, response, payload } = action

  switch (type) {
    case FETCH_REQUEST:
      return { ...state, response, loaded: true, error: false }

    case ERR:
      return { ...state, error: true }

    default:
      return state
  }
}

/**
 * Action Creators
 * */
export const fetchAll = (api, id, page) => async dispatch => {
  //  dispatch({ type: FETCH_REQUEST, response: mock })
  await axios
    .get(api + '/ajax/json.php', { params: { page, id } })
    .then(({ data }) => data)
    .then(response => {
      if (!response.error) return dispatch({ type: FETCH_REQUEST, response })
      dispatch({ type: ERR })
    }).catch(err => {
      Sentry.captureException(err);
      Sentry.captureMessage(err);
    })
}
