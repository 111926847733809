const Social = () => {
  return (
      <>
          <a href="https://t.me/estatet1" target="_blank" className="ico-11 social-item social-item--tg">Telegram</a>
          <a href="http://vk.com/est_a_tet" target="_blank" className="ico-12 social-item social-item--vk">Вконтакте</a>
          <a href="https://zen.yandex.ru/id/5a6731de830905930c30817f" target="_blank" className="ico-9 social-item social-item--yandex">Яндекс.Дзен</a>
          {/*<a href="https://www.instagram.com/estatet.ru/" target="_blank" className="ico-10 social-item social-item--insta">Instagram</a>*/}
          {/*<a href="http://www.youtube.com/user/Estatet" target="_blank" className="ico-13 social-item social-item--youtube">YouTube</a>*/}
          {/*<a href="https://ok.ru/group/59699382648875" target="_blank" className="ico-14 social-item social-item--ok">Одноклассники</a>*/}
          {/*<a href="https://www.facebook.com/pages/Est-a-Tet/382723445123319" target="_blank" className="ico-15 social-item social-item--fb">Facebook</a>*/}
      </>
    )
}

export default Social
