import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Slider from "react-slick";
import { SvgPopupClose, SvgSliderArrowLeft, SvgSliderArrowRight } from '../../icons';
import { setPopupPhoto } from '../../redux/modules/site';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style}}
      onClick={onClick}
    >
      <SvgSliderArrowRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style}}
      onClick={onClick}
    >
      <SvgSliderArrowLeft />
    </div>
  );
}

const PopupPhoto = () => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.popupPhoto);
  const slider = useRef(null)
  const [current, setCurrent] = useState(1)

  useEffect(() => {
    const body = document.body;
    if(data){
      body.classList.add('overflow');
    }
    else{
      body.classList.remove('overflow');
    }

    if(slider.current){
      slider.current.innerSlider.list.setAttribute('tabindex', 0);
      slider.current.innerSlider.list.focus();
    }

    setCurrent(1);

  },[data]);

  if(!data) return null;

  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    initialSlide: data.current || 0,
    beforeChange: (oldIndex, newIndex) => {
      setCurrent(newIndex + 1);
    }
  };

  return(
    <div className="popups fade-in popups-photos">
      <div className="popups__in">
        <div className="popups__close" onClick={() => dispatch(setPopupPhoto(null))}>
          <SvgPopupClose />
        </div>
        <div className="popups__slider">
          <div className="popups__slider-counter">{current} / {data.list.length}</div>
          <Slider {...settings} ref={slider}>
            {data.list.map((item, index) => {
              if(item.youtube){
                return (
                  <div key={`item${index}`}>
                    <div className="popups__slide">
                      <iframe src={item.youtube} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
                    </div>
                    {item.label && <div className="popups__label" dangerouslySetInnerHTML={{ __html: item.label }} />}
                    {item.date && <div className="popups__label" dangerouslySetInnerHTML={{ __html: item.date }} />}
                  </div>
                )
              }

              if(item.video){
                return (
                  <div key={`item${index}`}>
                    <div className="popups__slide">
                      <video controls>
                        <source src={item.video} type="video/mp4" />
                      </video>
                    </div>
                    {item.label && <div className="popups__label" dangerouslySetInnerHTML={{ __html: item.label }} />}
                    {item.date && <div className="popups__label" dangerouslySetInnerHTML={{ __html: item.date }} />}
                  </div>
                )
              }

              return (
                <div key={`item${index}`}>
                  <div className="popups__slide" style={{ backgroundImage: `url('${item.img}')`}} />
                  {item.label && <div className="popups__label" dangerouslySetInnerHTML={{ __html: item.label }} />}
                  {item.date && <div className="popups__label" dangerouslySetInnerHTML={{ __html: item.date }} />}
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default PopupPhoto;