import axios from 'axios'

/**
 * Constants
 * */
export const moduleName = 'promodetail'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`
export const SET_NEWS_FEED_TYPE = `${moduleName}/SET_NEWS_FEED_TYPE`
export const ERR = `${moduleName}/ERR`

const defaultState = {
    response: [],
    loaded: false
}

/**
 * Reducer
 * */
export default (state = defaultState, action) => {
    const { type, response, payload } = action

    switch (type) {
        case FETCH_REQUEST:
            return { ...state, response, loaded: true, error: false }
        case ERR:
            return { ...state, error: true }

        default:
            return state
    }
}

/**
 * Action Creators
 * */
export const fetchAll = (api, id) => async dispatch => {
    // dispatch({ type: FETCH_REQUEST, response: mock })
    await axios
        .get(api + '/ajax/json.php', { params: { page: 'actions', id: id } })
        .then(({ data }) => data)
        .then(response => {
            if (!response.error) return dispatch({ type: FETCH_REQUEST, response})
            dispatch({ type: ERR })
        })
}
