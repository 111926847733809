import axios from 'axios'
//import {newData} from '../mock/recommends'

export const moduleName = 'recommends'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`
export const SET_NEWS_FEED_TYPE = `${moduleName}/SET_NEWS_FEED_TYPE`

const defaultState = {
  response: {},
  loaded: false
}

export default (state = defaultState, action) => {
  const { type, response } = action

  switch (type) {
    case FETCH_REQUEST:
      return { ...state, response, loaded: true }

    default:
      return state
  }
}

export const fetchAll = (api, id) => async dispatch => {
  //dispatch({ type: FETCH_REQUEST, response: newData })
  await axios
    .get(api + '/ajax/json.php', { params: {page: 'recommends', set: id} })
    .then(({ data }) => data)
    .then(response => dispatch({ type: FETCH_REQUEST, response }))
}
