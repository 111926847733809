import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';

import {Provider, useSelector} from 'react-redux'
import Head from 'next/head'
import withReduxStore from '../redux/with-redux-store'
import App from 'next/app'
import Container from '../components/_app/container'
import 'slick-carousel/slick/slick.css'
import 'rc-slider/assets/index.css'
import '../../static/css/all.scss'
import {fetchMenuTop, fetchMenuMain, isMobile, setFirstLoad} from '../redux/modules/site'
//import {redir_n} from '../utils';

class MyApp extends App {

    componentDidMount() {
        const { reduxStore, pageProps } = this.props;
        const _api = pageProps?.api && typeof pageProps?.api !== 'undefined' ? pageProps.api : ''
      //const redirects = useSelector(redirectsSelector);

        let n_req = this.props.req;
        if(!n_req){
            n_req = {};
            n_req.originalUrl = this.props.router.asPath;
        }
/*      if(redirects?.redirects){
        let filtered_redirects = redirects?.redirects.filter(item => item.from === n_req)[0];
        if (filtered_redirects){
          this.props.res.redirect(filtered_redirects.to, '301');
        }
      }*/
      //redir_n(this.props.res, n_req, redirects?.redirects);

        reduxStore.dispatch(isMobile(window.innerWidth <= 1000))
        reduxStore.dispatch(setFirstLoad())
        reduxStore.dispatch(fetchMenuMain(_api))
        //reduxStore.dispatch(fetchMenuBottom(_api))
        reduxStore.dispatch(fetchMenuTop(_api))

        this._onResize()
        window.addEventListener('resize', () => {
            this._onResize()
        })


        const scrll = () => {
            const scr = window.scrollY;
            const lazzy = document.querySelectorAll('.lazzy');
            for(let i = 0; i < lazzy.length; i++){
                if(lazzy[i]){
                    const top = lazzy[i].getBoundingClientRect().top + scr - window.innerHeight;
                    const img = lazzy[i].getAttribute('data-image');
                    if(img){
                        if(scr >= top){
                            lazzy[i].style.backgroundImage = `url('${img}')`;
                        }
                    }
                }
            }
        }

        scrll();

        window.addEventListener('scroll', () => {
            const header = document.querySelector('.header')
            // const header_mobile = document.querySelector('.header-mobile')
            const element = document.querySelector('body')

            if (header && window.pageYOffset > header.offsetHeight) element.classList.add('scrolled')
            // else if (header_mobile && window.scrollY > header_mobile.offsetHeight) element.classList.add('scrolled')
            else element.classList.remove('scrolled')

            scrll();
        })


        window.store = reduxStore
    }

    render() {
        const { Component, pageProps, reduxStore, router } = this.props
        const query = router?.asPath?.split('?')
        const _api = pageProps?.api && typeof pageProps?.api !== 'undefined' ? pageProps.api : ''

        return (
            <>
                <Head>
                    {query?.length === 2 && <link rel="canonical" href={`https://www.estatet.ru${query[0]}`} />}
                    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
{/*
                    {router.asPath !== '/' && <script src={`https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=${process.env.YANDEX_MAP_API_KEY}`} />}
*/}
                </Head>
                <Provider store={reduxStore}>
                    <Container res={this.props.res} api={_api}><Component {...pageProps} api={_api} /></Container>
                </Provider>
            </>
        )
    }

    _onResize = () => {
        const { reduxStore } = this.props
        const mobile = window.innerWidth <= 1000

        if ((mobile && !reduxStore.getState().mobile) || (!mobile && reduxStore.getState().mobile)) {
            reduxStore.dispatch(isMobile(mobile))
        }
    }
}

export default withReduxStore(MyApp)
