import Link from 'next/link';
import {useState} from 'react'
import { useSelector } from 'react-redux';
import { SvgLikeActive, SvgLikeEmpty } from '../../icons';

const FavIcon = () => {
  const fav = useSelector(state => state.favorites);

  if(!fav) return null;

  const flats = fav.response.flats || [];
  const objects = fav.response.objects || [];
  const flatsCommerce = fav.response.flatsCommerce || [];
  const objectsCommerce = fav.response.objectsCommerce || [];
  const flatsCountry = fav.response.flatsCountry || [];
  const objectsCountry = fav.response.objectsCountry || [];

  const [open, setOpen] = useState(false)

  if(flats.length || objects.length || flatsCommerce.length || objectsCommerce.length || flatsCountry.length || objectsCountry.length){
    return (
      <Link href="/favorites">
        <a className="fav-icon scale-on-hover" onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
          <SvgLikeActive />
          {open && <div className="info-bubble">Избранное</div>}
        </a>
      </Link>
    )
  }

  return (
    <Link href="/favorites">
      <a className="fav-icon scale-on-hover fav-icon-empty" onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
        <SvgLikeEmpty />
        {open && <div className="info-bubble">Избранное</div>}
      </a>
    </Link>
  )
}

export default FavIcon