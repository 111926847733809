import { createSelector } from 'reselect'
import axios from "axios";
import * as Cookies from 'js-cookie'

export const moduleName = 'compare'
export const FETCH_REQUEST = `${moduleName}/FETCH_REQUEST`
export const REMOVE_ITEM = `${moduleName}/REMOVE_ITEM`

import mock from '../mock/compare'


const defaultState = {
  flats: [],
  loaded: false
}

export default (state = defaultState, action) => {
  const { response, type, payload } = action

  switch (type) {
    case FETCH_REQUEST:
      const flats = response.flats.reduce((acc, flat) => [...acc, {...flat, link: 'catalog_new'}], [])
      const commerce = response.flatsCommerce.reduce((acc, flat) => [...acc, {...flat, link: 'catalog_cre'}], [])
      return { ...state, flats: [...flats, ...commerce], loaded: true }

    case REMOVE_ITEM:
      return { ...state, flats: state.flats.filter(el => el.id !== payload.id) }

    default:
      return state
  }
}

export const loadedSelector = state => state[moduleName].loaded
export const flatsSelector = state => state[moduleName].flats


export const getIds = () => {
  if (!Cookies.get('compare')) return []
  return JSON.parse(Cookies.get('compare'))

}
// export const fetchAll = (api) => async dispatch => {
export const fetchAll = () => async dispatch => {
  const api = 'https://estatet2.multicontent.ru/'
  const ids = getIds()
  // return dispatch({ type: FETCH_REQUEST, response: mock })
  if (ids.length) {
    await axios
      .get(api + '/ajax/json.php', { params: {page: 'compare-list', flats: ids.toString()} })
      .then(({ data }) => data)
      .then(response => dispatch({ type: FETCH_REQUEST, response }))
  }
}

export const removeItem = id => dispatch => {
  const ids = getIds()
  dispatch({ type: REMOVE_ITEM, payload: {id} })
  Cookies.set('compare', ids.filter(el => el !== id), { path: '' })
}

export const setItem = (id, api) => dispatch => {
  const ids = getIds()
  Cookies.set('compare', [...ids, +id], { path: '' })
  dispatch(fetchAll(api))
}
